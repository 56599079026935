const { REACT_APP_DOMAIN_ENV, REACT_APP_TARGET_ENV } = process.env;
const config = {
  local: {
    apiEndpoint: "https://ks.api.remaster.io",
    infoUrl: `http://localhost:3000`,
    marketUrl: `http://localhost:3001`,
    domain: REACT_APP_DOMAIN_ENV,
  },
  stage: {
    apiEndpoint: "https://ks.api.remaster.io",
    infoUrl: "//rms.remaster.io",
    marketUrl: "//marketplace.rms.remaster.io",
    domain: REACT_APP_DOMAIN_ENV,
  },
  prod: {
    apiEndpoint: "https://ks.api.remaster.io",
    infoUrl: "https://remaster.io",
    marketUrl: "https://marketplace.remaster.io",
    domain: REACT_APP_DOMAIN_ENV,
  },
}[REACT_APP_TARGET_ENV];

export default config;
