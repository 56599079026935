import React, { useState, useEffect } from 'react';
import { Plus, Minus } from '@phosphor-icons/react';
import Img1 from './Img1.svg'; 
import Img3 from './Img3.svg';
import Footer from '../Footer';
import Image1 from './Flower.svg';
import Image2 from './Flower (1).svg';
import Image3 from './Flower (2).svg';
import Image4 from './Flower (3).svg';
import Img4 from './co-2 1.svg';
import { Skeleton } from 'antd';

const accordionData = [
  {
    title: "No investment, no inventory.",
    content:
      "Create a free REMASTER Showcase page. Product Makers will reach out to you and ask for a proposal. It’s as easy as that.",
  },
  {
    title: "Legal docs, simplified.",
    content:
      "REMASTER walks you through the entire process of creating a proposal, negotiating a license, and signing a deal.",
  },
  {
    title: "Maintain creative control.",
    content:
      "It’s your content. You make the rules about how it is used. REMASTER includes art submission and approval tools for every contract.",
  },
  {
    title: "Royalties and payments come to you.",
    content:
      "You manage the entire relationship on REMASTER. Once you have set up your secure, verified account, you can sit back and watch your payments roll in.",
  },
  {
    title: "It's not just about products.",
    content:
      "REMASTER is expanding to help content owners with all kinds of legal agreements related to your intellectual property.",
  },
];


const ContentOwner = () => {
  const [loadingImg1, setLoadingImg1] = useState(true);
  const [loadingImg2, setLoadingImg2] = useState(true);
  const [loadingImg3, setLoadingImg3] = useState(true);
  const [loadingImg4, setLoadingImg4] = useState(true);
  const [loadingImg5, setLoadingImg5] = useState(true);
  const [loadingImg6, setLoadingImg6] = useState(true);

  const handleImageLoad1 = () => {
    setLoadingImg1(false);
  };

  const handleImageLoad2 = () => {
    setLoadingImg2(false);
  };

  const handleImageLoad3 = () => {
    setLoadingImg3(false);
  };

  const handleImageLoad4 = () => {
    setLoadingImg4(false);
  };

  const handleImageLoad5 = () => {
    setLoadingImg5(false);
  };

  const handleImageLoad6 = () => {
    setLoadingImg6(false);
  };

  useEffect(() => {
    const img1 = new Image();
    img1.src = Img1;
    img1.onload = handleImageLoad1;

    const img2 = new Image();
    img2.src = Img4;
    img2.onload = handleImageLoad2;

    const img3 = new Image();
    img3.src = Image1;
    img3.onload = handleImageLoad3;

    const img4 = new Image();
    img4.src = Image2;
    img4.onload = handleImageLoad4;

    const img5 = new Image();
    img5.src = Image3;
    img5.onload = handleImageLoad5;

    const img6 = new Image();
    img6.src = Image4;
    img6.onload = handleImageLoad6;
  }, []);

  const [activeIndex, setActiveIndex] = useState(null);
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="w-fit lg:w-auto">
      <div className="border border-white-500 bg-[#E7E7E7]">
        <div className="w-[1348px] h-auto mt-[80px] lg:mb-[115px] mx-auto">
          <div className="flex flex-col lg:flex-row justify-between w-full space-x-0 sm:space-x-6 items-center px-[24px]">
            <div className="w-[645px] sm:w-[645px] h-auto flex flex-col justify-between my-auto mx-auto">
              <div className="space-y-4">
                <div className="font-gt-america font-bold text-custom-33 leading-custom-44 text-left decoration-skip-ink-none mb-[36px]">
                  Content Owners
                </div>
                <div className="font-gt-america font-normal text-custom-33 leading-custom-44 text-left decoration-skip-ink-none">
                  COMPANIES WANT TO TURN YOUR CONTENT INTO PRODUCTS.
                </div>
                <div className="font-Satoshi font-medium text-custom-20 leading-custom-30 text-left decoration-skip-ink-none">
                  Product makers find you on REMASTER.
                  <br />
                  They make and sell products featuring your creations. <br />
                  You get paid.
                  <br />
                  It’s as easy as that.
                </div>
              </div>
              <button
                className="cursor-pointer mt-[60px] w-[292px] h-[72px] px-[32px] py-[16px] font-satoshi text-[18px] font-extrabold leading-[28px] text-center underline-from-font decoration-none bg-[#712EFF] text-white"
                onClick={() =>
                  window.open(
                    "https://beta.commerce.remaster.io/seller-list",
                    "_blank"
                  )
                }
              >
                Browse Licensable Content
              </button>
            </div>
            <div className="w-full sm:w-[679px] my-[20px] sm:my-0 sm:h-[503px] mx-auto sm:ml-auto sm:mx-0">
              {loadingImg1 ? (
                <Skeleton.Image
                  active
                  className="w-full sm:h-full h-[241px] object-contain"
                />
              ) : (
                <img
                  src={Img1}
                  alt="HP-1 1"
                  className="w-full h-full object-contain"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full max-w-[1348px] h-auto lg:mt-[80px] mt-[40px] mx-auto lg:mb-[120px]">
        <div className="flex flex-col lg:flex-row lg:space-x-6 justify-between px-[24px]">
          <div className="h-auto w-full md:w-[726px] mx-auto lg:mx-0">
            <div className="mb-6">
              <img
                src={Img3}
                alt="HP-1 1"
                className="w-[40px] h-[40px] object-contain"
              />
            </div>
            <div className="md:w-[687px] h-auto mb-[24px]">
              <div className="font-satoshi text-[34px] font-bold mb-[24px] leading-[30px] text-left underline-from-font decoration-none">
                Licensing just got a lot easier.
              </div>
              <div className="font-satoshi text-[20px] font-medium leading-[30px] text-left underline-from-font decoration-none">
                Product makers pay to use your content—like your song in a video
                game or your artwork on apparel. As a content owner, you don’t
                have to invest time or money or inventory. You do keep creative
                control. And you get paid.
              </div>
            </div>

            <div className="w-full sm:w-[602px] h-auto mb-[24px]">
              {accordionData.map((item, index) => (
                <div key={index}>
                  <div className="flex justify-between items-center w-full h-[78px] border-b border-black">
                    <div className="w-full font-satoshi text-[20px] font-medium leading-[30px] text-left">
                      {item.title}
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => toggleAccordion(index)}
                    >
                      {activeIndex === index ? (
                        <Minus size={24} />
                      ) : (
                        <Plus size={24} />
                      )}
                    </div>
                  </div>
                  {activeIndex === index && (
                    <div className="font-satoshi text-[20px] font-medium leading-[30px] text-left mt-2">
                      {item.content}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <button
              className="cursor-pointer w-[314px] h-[60px] py-[16px] font-satoshi text-[18px] font-extrabold leading-[28px] text-center underline-from-font decoration-none bg-[#712EFF] text-white mt-auto"
              onClick={() =>
                window.open(
                  "https://beta.commerce.remaster.io/signup",
                  "_blank"
                )
              }
            >
              Create Content Owner Account
            </button>
          </div>
          <div className="w-full md:w-[598px] sm:h-[454px] mt-[20px] lg:mt-[115px] mb-[20px] lg:mb-[115px] flex justify-center mx-auto">
            {loadingImg2 ? (
              <Skeleton.Image
                active
                className="w-full sm:h-full h-[248px] object-contain"
              />
            ) : (
              <img
                src={Img4}
                alt="HP-1 1"
                className="w-full h-full object-contain"
              />
            )}
          </div>
        </div>
      </div>

      <div className="w-[1348px] mx-auto px-[24px] sm:my-0 my-[20px]">
        <div className="mx-auto lg:mx-0 w-[760px]">
          <div className="mb-[24px]">
            <img
              src={Img3}
              alt="HP-1 1"
              className="w-[40px] h-[40px] object-contain"
            />
          </div>
          <div class="font-satoshi lg:mb-[60px] mb-[30px] text-[34px] font-bold leading-[30px] text-left underline-from-font decoration-none">
            Lucrative licensing deals are just five steps away
          </div>
        </div>

        <div className="w-[1348px] mb-[74px]">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-cols-2 md:gap-y-[64px] gap-y-[30px]">
            <div className="w-[556px] lg:h-[537px] mx-auto flex flex-col justify-between sm:space-y-9 space-y-4">
              <div className="flex space-x-4 items-center">
                <div className="font-satoshi text-[99px] font-medium leading-[30px] text-center decoration-skip-ink-none">
                  1
                </div>
                <div className="space-y-1">
                  <div className="font-satoshi text-[22px] font-bold leading-[30px] text-left decoration-skip-ink-none">
                    Create Your Free REMASTER Showcase Page
                  </div>
                  <div className="font-satoshi text-[16px] font-medium leading-[24px] text-left decoration-skip-ink-none">
                    Upload all of your art, music, characters, videos, or
                    anything else you create.
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-[556px] lg:h-[419px] mx-auto mt-auto">
                {loadingImg3 ? (
                  <Skeleton.Image
                    active
                    className="w-full sm:h-full h-[247px] object-contain"
                  />
                ) : (
                  <img
                    src={Image1}
                    alt="Img4"
                    className="sm:w-[556px] lg:h-[419px] object-contain"
                  />
                )}
              </div>
            </div>

            <div className="w-[556px] lg:h-[537px] mx-auto flex flex-col justify-between sm:space-y-9 space-y-4">
              <div className="flex space-x-4 items-center">
                <div className="font-satoshi text-[99px] font-medium leading-[30px] text-center decoration-skip-ink-none">
                  2
                </div>
                <div className="space-y-1">
                  <div className="font-satoshi text-[22px] font-bold leading-[30px] text-left decoration-skip-ink-none">
                    Get Inquiries & Create Proposals
                  </div>
                  <div className="font-satoshi text-[16px] font-medium leading-[24px] text-left decoration-skip-ink-none">
                    Fill out a quick form to create proposals for product makers
                    that want to license your content.
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-[556px] lg:h-[419px] mx-auto mt-auto">
                {loadingImg4 ? (
                  <Skeleton.Image
                    active
                    className="w-full sm:h-full h-[247px] object-contain"
                  />
                ) : (
                  <img
                    src={Image2}
                    alt="Img4"
                    className="sm:w-[556px] lg:h-[419px] object-contain"
                  />
                )}
              </div>
            </div>

            <div className="w-[556px] lg:h-[537px] mx-auto flex flex-col justify-between sm:space-y-9 space-y-4">
              <div className="flex space-x-4 items-center">
                <div className="font-satoshi text-[99px] font-medium leading-[30px] text-center decoration-skip-ink-none">
                  3
                </div>
                <div className="space-y-1">
                  <div className="font-satoshi text-[22px] font-bold leading-[30px] text-left decoration-skip-ink-none">
                    Turn Conversations into Contracts
                  </div>
                  <div className="font-satoshi text-[16px] font-medium leading-[24px] text-left decoration-skip-ink-none">
                    Use REMASTER’s templates and workflow to review offers,
                    negotiate, and sign deals quickly, easily, and
                    inexpensively.
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-[556px] lg:h-[419px] mx-auto mt-auto">
                {loadingImg5 ? (
                  <Skeleton.Image
                    active
                    className="w-full sm:h-full h-[247px] object-contain"
                  />
                ) : (
                  <img
                    src={Image3}
                    alt="Img4"
                    className="sm:w-[556px] lg:h-[419px] object-contain"
                  />
                )}
              </div>
            </div>

            <div className="w-[556px] lg:h-[537px] mx-auto flex flex-col justify-between sm:space-y-9 space-y-4">
              <div className="flex space-x-4 items-center">
                <div className="font-satoshi text-[99px] font-medium leading-[30px] text-center decoration-skip-ink-none">
                  4
                </div>
                <div className="space-y-1">
                  <div className="font-satoshi text-[22px] font-bold leading-[30px] text-left decoration-skip-ink-none">
                    Manage and Earn Like a Boss
                  </div>
                  <div className="font-satoshi text-[16px] font-medium leading-[24px] text-left decoration-skip-ink-none">
                    Manage your licensing contracts, art approvals, payments,
                    and communication on your REMASTER dashboard.
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-[556px] lg:h-[419px] mx-auto mt-auto">
                {loadingImg6 ? (
                  <Skeleton.Image
                    active
                    className="w-full sm:h-full h-[247px] object-contain"
                  />
                ) : (
                  <img
                    src={Image4}
                    alt="Img4"
                    className="sm:w-[556px] lg:h-[419px] object-contain"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="mx-auto text-center mt-[60px]">
            <button
              className="cursor-pointer w-[314px] h-[60px] py-[16px] font-satoshi text-[18px] font-extrabold leading-[28px] text-center underline-from-font decoration-none bg-[#712EFF] text-white mt-auto"
              onClick={() =>
                window.open(
                  "https://beta.commerce.remaster.io/signup",
                  "_blank"
                )
              }
            >
              Create your Content Showcase
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContentOwner;
