import React, { useState, useEffect } from 'react';
import { Plus, Minus } from '@phosphor-icons/react';
import Img1 from './Img1.svg'; 
import Img2 from './Img2.svg';
import Img8 from './Img8.svg';
import Footer from '../Footer';
import Image1 from './Flower (4).svg';
import Image2 from './Flower (5).svg';
import Image3 from './Flower (6).svg';
import Image4 from './Flower (7).svg';
import { Skeleton } from 'antd';

const accordionData = [
  {
    title: "Access a Treasure Trove of Emerging Content",
    content:
      "REMASTER gives you direct access to a curated marketplace of emerging content that will be shaping consumer culture.",
  },
  {
    title: "Source Content for Any Product, Any Market",
    content:
      "Whether you’re looking for eye-catching designs for apparel, engaging characters for video games, or captivating artwork for digital merchandise, REMASTER offers a diverse range of content that can be easily adapted and scaled across various product categories and markets.",
  },
  {
    title: "Say Goodbye to Licensing Headaches",
    content:
      "REMASTER streamlines the entire licensing process, making it faster, easier, and more transparent than ever before. REMASTER simplifies negotiations, reduces legal hassles, and automates key workflows, so you can focus on what matters most – bringing exceptional products to market.",
  },
  {
    title: "Maximize ROI with Data-Driven Insights",
    content:
      "REMASTER provides valuable data and analytics to help you make informed decisions about licensing opportunities. Gain insights into content performance, audience demographics, and market trends, empowering you to select the most commercially viable IP for your product lines.",
  },
  {
    title: "Deals Done in Days",
    content:
      "Simplification means much faster time to deal closing and ultimately getting to market sooner.",
  },
];

const ProductMaker = () => {
  const [loadingImg1, setLoadingImg1] = useState(true); 
  const [loadingImg2, setLoadingImg2] = useState(true); 
  const [loadingImg3, setLoadingImg3] = useState(true); 
  const [loadingImg4, setLoadingImg4] = useState(true); 
  const [loadingImg5, setLoadingImg5] = useState(true); 
  const [loadingImg6, setLoadingImg6] = useState(true); 

  const handleImageLoad1 = () => {
    setLoadingImg1(false); 
  };

  const handleImageLoad2 = () => {
    setLoadingImg2(false); 
  };

  const handleImageLoad3 = () => {
    setLoadingImg3(false); 
  };

  const handleImageLoad4 = () => {
    setLoadingImg4(false); 
  };

  const handleImageLoad5 = () => {
    setLoadingImg5(false); 
  };

  const handleImageLoad6 = () => {
    setLoadingImg6(false); 
  };

  useEffect(() => {
    const img1 = new Image();
    img1.src = Img1;
    img1.onload = handleImageLoad1;

    const img2 = new Image();
    img2.src = Img2;
    img2.onload = handleImageLoad2;

    const img3 = new Image();
    img3.src = Image1;
    img3.onload = handleImageLoad3;

    const img4 = new Image();
    img4.src = Image2;
    img4.onload = handleImageLoad4;

    const img5 = new Image();
    img5.src = Image3;
    img5.onload = handleImageLoad5;

    const img6 = new Image();
    img6.src = Image4;
    img6.onload = handleImageLoad6;
  }, []);

  const [activeIndex, setActiveIndex] = useState(null);
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index); 
  };

  return (
    <div className="w-fit lg:w-auto">
      <div className="border border-white-500 bg-[#E7E7E7]">
        <div className="w-[1348px] h-auto mt-[80px] lg:mb-[132px] mx-auto">
          <div className="flex flex-col lg:flex-row justify-between w-full space-x-0 sm:space-x-6 items-center px-[24px]">
            <div className="w-full sm:w-[772px] h-auto flex flex-col justify-between my-auto mx-auto">
              <div className="space-y-4">
                <div className="font-gt-america font-bold text-custom-33 leading-custom-44 text-left decoration-skip-ink-none mb-[36px]">
                  Product Makers
                </div>
                <div className="font-gt-america font-normal text-custom-33 leading-custom-44 text-left decoration-skip-ink-none">
                  DISCOVER, BUY, AND MANAGE LICENSES USING REMASTER’S
                  STREAMLINED LICENSING INFRASTRUCTURE
                </div>
                <div className="font-Satoshi font-medium text-custom-20 leading-custom-30 text-left decoration-skip-ink-none">
                  REMASTER provides a seamless, end-to-end platform that
                  connects you with a vast network of emerging content creators,
                  simplifies licensing agreements, and unlocks incredible
                  revenue potential.
                </div>
              </div>
              <button
                className="cursor-pointer mt-[60px] w-[292px] h-[72px] px-[32px] py-[16px] font-satoshi text-[18px] font-extrabold leading-[28px] text-center underline-from-font decoration-none bg-[#712EFF] text-white"
                onClick={() =>
                  window.open(
                    "https://beta.commerce.remaster.io/seller-list",
                    "_blank"
                  )
                }
              >
                Browse Licensable Content
              </button>
            </div>
            <div className="w-full sm:w-[552px] sm:h-[476px] mx-auto sm:mx-0 my-[40px] sm:my-0">
              {loadingImg1 ? (
                <Skeleton.Image
                  active
                  className="w-full sm:h-full h-[281px] object-contain"
                />
              ) : (
                <img
                  src={Img1}
                  alt="HP-1 1"
                  className="w-full h-full object-contain"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full max-w-[1348px] h-auto mt-[40px] mx-auto lg:mb-[120px]">
        <div className="flex flex-col lg:flex-row lg:space-x-6 justify-between px-[24px]">
          <div className="h-auto w-full sm:w-[632px] mx-auto">
            <div className="mb-[24px]">
              <img
                src={Img8}
                alt="HP-1 1"
                className="w-[40px] h-[40px] object-contain"
              />
            </div>
            <div className="w-full sm:w-[632px] h-auto mb-[24px]">
              <div className="font-satoshi text-[34px] font-bold leading-[35px] text-left underline-from-font decoration-none">
                All the Tools You Need <br />
                to Expand Through Licensing
              </div>
            </div>

            <div className="w-full sm:w-[602px] h-auto mb-[24px]">
              {accordionData.map((item, index) => (
                <div key={index}>
                  <div className="flex justify-between items-center w-full h-[78px] border-b border-black">
                    <div className="w-full font-satoshi text-[20px] font-medium leading-[30px] text-left">
                      {item.title}
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => toggleAccordion(index)}
                    >
                      {activeIndex === index ? (
                        <Minus size={24} />
                      ) : (
                        <Plus size={24} />
                      )}
                    </div>
                  </div>
                  {activeIndex === index && (
                    <div className="font-satoshi text-[20px] font-medium leading-[30px] text-left mt-2">
                      {item.content}
                    </div>
                  )}
                </div>
              ))}
            </div>

            <button
              className="cursor-pointer mt-[24px] w-[328px] h-[60px] flex items-center justify-center font-satoshi text-[18px] font-extrabold leading-[28px] text-center underline-from-font decoration-none bg-[#712EFF] text-white"
              onClick={() =>
                window.open("https://beta.commerce.remaster.io", "_blank")
              }
            >
              Browse REMASTER Marketplace
            </button>
          </div>
          <div className="w-full sm:w-[692px] sm:h-[472px] mt-[20px] lg:mt-[115px] mb-[20px] lg:mb-[115px] flex justify-center mx-auto">
            {loadingImg2 ? (
              <Skeleton.Image
                active
                className="w-full sm:h-full h-[224px] object-contain"
              />
            ) : (
              <img
                src={Img2}
                alt="HP-1 1"
                className="w-full h-full object-contain"
              />
            )}
          </div>
        </div>
      </div>

      <div className="w-[1348px] mx-auto px-[24px] sm:my-0 my-[20px]">
        <div className="mx-auto w-[750px] lg:mx-0">
          <div className="mb-[24px]">
            <img
              src={Img8}
              alt="HP-1 1"
              className="w-[40px] h-[40px] object-contain"
            />
          </div>
          <div class="font-satoshi text-[34px] font-bold leading-[30px] mb-[60px] text-left underline-from-font decoration-none">
            End-to-End Licensing Tools for Product Makers
          </div>
          {/* <div class="font-satoshi mb-[24px] text-[20px] font-medium leading-[30px] text-left underline-from-font decoration-none">
            How it Works
          </div> */}
        </div>

        <div className="w-[1348px] mb-[74px]">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-cols-2 md:gap-y-[64px] gap-y-[30px]">
            <div className="w-[556px] lg:h-[561px] mx-auto flex flex-col justify-between sm:space-y-9 space-y-4">
              <div className="flex space-x-4 items-center">
                <div className="font-satoshi text-[99px] font-medium leading-[30px] text-center decoration-skip-ink-none">
                  1
                </div>
                <div className="space-y-1">
                  <div className="font-satoshi text-[22px] font-bold leading-[30px] text-left decoration-skip-ink-none">
                    Discover & Connect
                  </div>
                  <div className="font-satoshi text-[16px] font-medium leading-[24px] text-left decoration-skip-ink-none">
                    REMASTER curates a marketplace of emerging content and their
                    unique and commercially viable IP. Partner with rising stars
                    before they hit the mainstream.
                  </div>
                </div>
              </div>
              {loadingImg3 ? (
                <Skeleton.Image
                  active
                  className="w-full sm:h-full h-[247px] object-contain mt-auto"
                />
              ) : (
                <img
                  src={Image1}
                  alt="Img4"
                  className="sm:w-[556px] lg:h-[419px] object-contain mt-auto"
                />
              )}
            </div>

            <div className="w-[556px] lg:h-[561px] mx-auto flex flex-col justify-between space-y-4 lg:space-y-0">
              <div className="flex space-x-4 items-center">
                <div className="font-satoshi text-[99px] font-medium leading-[30px] text-center decoration-skip-ink-none">
                  2
                </div>
                <div className="space-y-1">
                  <div className="font-satoshi text-[22px] font-bold leading-[30px] text-left decoration-skip-ink-none">
                    Negotiate & License
                  </div>
                  <div className="font-satoshi text-[16px] font-medium leading-[24px] text-left decoration-skip-ink-none">
                    AI-powered natural-language negotiation and legal
                    documentation get IP owners and product makers to signed
                    agreements fast.
                  </div>
                </div>
              </div>
              {loadingImg4 ? (
                <Skeleton.Image
                  active
                  className="w-full sm:h-full h-[247px] object-contain mt-auto"
                />
              ) : (
                <img
                  src={Image2}
                  alt="Img4"
                  className="sm:w-[556px] lg:h-[419px] object-contain mt-auto"
                />
              )}
            </div>

            <div className="w-[556px] lg:h-[561px] mx-auto flex flex-col justify-between sm:space-y-9 space-y-4">
              <div className="flex space-x-4 items-center">
                <div className="font-satoshi text-[99px] font-medium leading-[30px] text-center decoration-skip-ink-none">
                  3
                </div>
                <div className="space-y-1">
                  <div className="font-satoshi text-[22px] font-bold leading-[30px] text-left decoration-skip-ink-none">
                    Manage Everything
                  </div>
                  <div className="font-satoshi text-[16px] font-medium leading-[24px] text-left decoration-skip-ink-none">
                    From design approvals to payment management, you and your IP
                    partners can manage your entire relationship on REMASTER.
                  </div>
                </div>
              </div>
              {loadingImg5 ? (
                <Skeleton.Image
                  active
                  className="w-full sm:h-full h-[247px] object-contain mt-auto"
                />
              ) : (
                <img
                  src={Image3}
                  alt="Img4"
                  className="sm:w-[556px] lg:h-[419px] object-contain mt-auto"
                />
              )}
            </div>

            <div className="w-[556px] lg:h-[561px] mx-auto flex flex-col justify-between sm:space-y-9 space-y-4">
              <div className="flex space-x-4 items-center">
                <div className="font-satoshi text-[99px] font-medium leading-[30px] text-center decoration-skip-ink-none">
                  4
                </div>
                <div className="space-y-1">
                  <div className="font-satoshi text-[22px] font-bold leading-[30px] text-left decoration-skip-ink-none">
                    Repeat & Expand
                  </div>
                  <div className="font-satoshi text-[16px] font-medium leading-[24px] text-left decoration-skip-ink-none">
                    Economies of scale is the name of the game at REMASTER. Most
                    licensees expand from one license agreement to dozens within
                    weeks
                  </div>
                </div>
              </div>
              {loadingImg6 ? (
                <Skeleton.Image
                  active
                  className="w-full sm:h-full h-[247px] object-contain mt-auto"
                />
              ) : (
                <img
                  src={Image4}
                  alt="Img4"
                  className="sm:w-[556px] lg:h-[419px] object-contain mt-auto"
                />
              )}
            </div>
          </div>
          <div className="mx-auto text-center mt-[60px]">
            <button
              className="cursor-pointer w-[360px] sm:h-[60px] h-auto py-[16px] font-satoshi text-[18px] font-extrabold leading-[28px] text-center underline-from-font decoration-none bg-[#712EFF] text-white mt-auto"
              onClick={() =>
                window.open("https://beta.commerce.remaster.io/", "_blank")
              }
            >
              Discover & Connect in our Marketplace
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductMaker;
