import classNames from "classnames";
import React from "react";
import { TypeAnimation } from "react-type-animation";
import { NotifyForm } from "../Form/Form";

const typedText = [
  ...[`SMARTER CONTRACTS`, 2000],
  ...[`MORE ENFORCEABILITY`, 2000],
  ...["LESS FRICTION", 2000],
];

const Banner = () => {
  return (
    <section className="container py-6 pt-20 md:pt-24 xl:pt-36">
      <div className="flex flex-col-reverse sm:grid sm:grid-cols-12 gap-8">
        <div className="sm:col-start-4 xl:col-start-5 sm:mt-36 xl:-mt-28 xl:ml-7 sm:row-start-1 sm:col-span-9 xl:col-span-8 w-full aspect-[792/629]">
          <img
            src="./banner.svg"
            alt="Banner"
            className="object-cover w-full h-full"
          />
        </div>
        <h1
          className={classNames(
            "xl:col-span-7 sm:col-start-1 xl:col-start-1 sm:row-start-1 sm:col-span-9",
            "h-[calc(42px_*_5)] md:h-auto"
          )}
        >
          REMASTERING THE FUTURE OF COMMERCE WITH{" "}<br/>
          <span className="text-rms-grey">
            <TypeAnimation
              sequence={typedText}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
            />
          </span>
        </h1>
      </div>
    </section>
  );
};

export const MarketplaceBanner = () => {
  return (
    <section className="container py-6 sm:pb-0 pt-8 sm:pt-20 md:pt-24 xl:pt-8 xl:pb-14">
      <div className="flex flex-col-reverse sm:grid sm:grid-cols-12 gap-8">
        <div className="sm:col-start-4 xl:col-start-6  xl:row-start-1 sm:col-span-9 xl:col-span-7 w-full aspect-[327/295] sm:aspect-[1.43] xl:aspect-[677/611] overflow-hidden">
          <img
            src="./marketplaceBanner.webp"
            className=" object-cover w-full h-full object-top"
          />
        </div>
        <div className="sm:col-start-1 xl:col-start-1 sm:row-start-1 sm:col-span-8 xl:col-span-5 xl:mt-32">
          <p className="font-medium uppercase">Remastering Licensing</p>
          <h1>
            Your IP <br />
            Marketplace
          </h1>

          <NotifyForm />
        </div>
      </div>
    </section>
  );
};

export default Banner;
