import classNames from "classnames";
import React from "react";
import { Link } from "react-scroll";
import {
  ArrowUpRight as ArrowUpRightIcon,
  TwitterLogo as TwitterLogoIcon,
  LinkedinLogo as LinkedinLogoIcon,
} from "@phosphor-icons/react";
import gsap from "gsap";
import { Link as BrowserLink } from "react-router-dom";
import ScrollTo from "gsap/ScrollToPlugin";
import { useRef } from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import config from "../../config";

gsap.registerPlugin(ScrollTo);

const socials = [
  {
    id: "twitter",
    content: (
      <a
        href="https://twitter.com/Remaster_io"
        target="_blank"
        rel="noreferrer"
      >
        <TwitterLogoIcon size={24} weight="light" />
      </a>
    ),
    target: "twitter",
  },
  {
    id: "linkedin",
    content: (
      <a
        href="https://www.linkedin.com/company/remasterlegal"
        target="_blank"
        rel="noreferrer"
      >
        <LinkedinLogoIcon size={24} weight="light" />
      </a>
    ),
    target: "linkedin",
  },
];

const navItems = {
  default: [
    {
      id: "home",
      content: "Home",
      target: "home",
      scroll: true,
    },
    {
      id: "products",
      content: "Products",
      target: "products",
      scroll: true,
    },
    {
      id: "contact",
      content: "Contact",
      target: "contact",
      scroll: true,
    },
    ...socials,
    {
      id: "marketplace",
      content: (
        <a
          href={config.marketUrl}
          target="_blank"
          className="sm:bg-black bg-transparent sm:text-white text-black p-6 flex gap-2 items-center text-base/7 font-bold"
        >
          View IP Marketplaces <ArrowUpRightIcon size={24} />
        </a>
      ),
      target: "marketplace",
    },
  ],
  marketplace: [
    {
      id: "home",
      content: "Home",
      target: "home",
      scroll: true,
    },
    {
      id: "how-it-works",
      content: "How It Works",
      target: "how-it-works",
      scroll: true,
    },
    {
      id: "join",
      content: "Join",
      target: "join",
      scroll: true,
    },
    ...socials,
    {
      id: "signup",
      content: (
        <button className="sm:bg-black bg-transparent sm:text-white text-black p-6 xl:px-24 flex gap-2 items-center text-base/7 font-bold">
          SIGN UP
        </button>
      ),
      target: "join",
      scroll: true,
    },
  ],
};

const Navbar = ({ type }) => {
  const [activeSectionId, setActiveSection] = useState(null);
  const [intermediateSectionId, setIntermediateSectionId] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const mainHeaderRef = useRef(null);
  const placeholderRef = useRef(null);

  useLayoutEffect(() => {
    const calculatedHeight = mainHeaderRef.current.offsetHeight;
    placeholderRef.current.style.cssText = `height:${calculatedHeight}px`;
    setHeaderHeight(calculatedHeight);
  }, []);

  const handleSetActive = (newActiveSectionId) => () => {
    setIntermediateSectionId(newActiveSectionId);
  };

  const handleScrollSection = (newActiveSectionId) => () => {
    if (!intermediateSectionId) {
      setActiveSection(newActiveSectionId);
    } else if (newActiveSectionId === intermediateSectionId) {
      setActiveSection(newActiveSectionId);
      setIntermediateSectionId(null);
    }
  };
  return (
    <header>
      <div className="fixed top-0 w-full z-20 bg-white">
        <div
          ref={mainHeaderRef}
          className="m-auto flex justify-between 2xl:container pl-6 lg:pl-12 p-0 2xl:p-0"
        >
          <BrowserLink
            to={type !== "marketplace" ? "/" : config.infoUrl}
            className="py-6 flex-shrink-0 flex items-center"
          >
            <img
              alt="Remaster logo"
              className="hidden sm:block"
              src="./logo.svg"
            />
            <img
              alt="Remaster logo"
              className="sm:hidden"
              src="./logo-mobile.svg"
            />
          </BrowserLink>
          <nav>
            <ul className="flex gap-12">
              {navItems[type || "default"].map((navItem, index) => (
                <React.Fragment key={`${navItem.id}-${index}`}>
                  <li
                    className={classNames(
                      "flex-shrink-0 ",
                      navItem.scroll &&
                        navItem.id !== "signup" &&
                        "hidden lg:block",
                      ["twitter", "linkedin"].includes(navItem.id) &&
                        "hidden sm:block",
                      "relative"
                    )}
                  >
                    <div className="relative h-full grid items-center">
                      {navItem.scroll ? (
                        <>
                          <Link
                            className={classNames(
                              "cursor-pointer self-center row-span-2",
                              navItem.target === activeSectionId && "font-bold"
                            )}
                            spy={true}
                            duration={300}
                            onClick={handleSetActive(navItem.target)}
                            onSetActive={handleScrollSection(navItem.target)}
                            to={navItem.target}
                            smooth={true}
                            offset={-headerHeight}
                          >
                            {navItem.content}
                          </Link>
                        </>
                      ) : (
                        navItem.content
                      )}
                    </div>
                    <div
                      className={classNames(
                        "absolute bottom-0 w-full border-b-4 border-solid",
                        activeSectionId === navItem.target
                          ? "border-black"
                          : "border-transparent"
                      )}
                    />
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </nav>
        </div>
        <hr className="mix-blend-darken -mt-[1px]" />
      </div>
      <div ref={placeholderRef} />
    </header>
  );
};

export default Navbar;
