import Home from "./pages/Home"
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Saas from "./pages/Saas";
import HomePage from "./v3_pages/home"; 
import ContentOwner from "./v3_pages/content_owners"; 
import ProductMakers from "./v3_pages/product_makers"; 
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Marketplace from "./pages/Marketplace";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import config from "./config";
import Navbar from "./v3_pages/Navbar";

const Root = () => {
  return (
    <div className="w-fit lg:w-auto overflow-x-hidden">
      <Navbar />
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: config.domain === "info"
      ? [
          // { path: "", element: <Home /> },
          { path: "terms", element: <Terms /> },
          { path: "privacy", element: <Privacy /> },
          { path: "saas", element: <Saas /> },
          { path: "", element: <HomePage /> },
          { path: "content_owner", element: <ContentOwner /> },
          { path: "product_maker", element: <ProductMakers /> },
        ]
      : [
          { path: "", element: <Marketplace /> }
        ],
  },
]);


function App() {
  return (
    <div id="remaster-body" className="App antialiased w-fit lg:w-auto">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // theme="colored"
      />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
