import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import Branding from "../../components/Branding";
import { content } from "./html_doc";
import { Styles } from "./Styled";

const Terms = () => {
  return (
    <div className="h-screen overflow-y-scroll">
      <article className="container mx-auto my-10 prose prose-ol:pl-0 prose-ol:list-inside">
        <Branding />
        <h1>Remaster Terms of Service</h1>
        <Styles>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>
        </Styles>
      </article>
    </div>
  );
};

export default Terms;
