import { styled } from "styled-components";

export const Styles = styled.div`
  ${() => String.raw`
  @import url(https://themes.googleusercontent.com/fonts/css?kit=wAPX1HepqA24RkYW1AuHYA);

  .lst-kix_list_14-1>li:before {
      content: "o  "
  }
  
  .lst-kix_list_14-3>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_2-1>li {
      counter-increment: lst-ctn-kix_list_2-1
  }
  
  ol.lst-kix_list_15-6 {
      list-style-type: none
  }
  
  ol.lst-kix_list_15-7 {
      list-style-type: none
  }
  
  ol.lst-kix_list_15-8 {
      list-style-type: none
  }
  
  ol.lst-kix_list_9-0.start {
      counter-reset: lst-ctn-kix_list_9-0 0
  }
  
  .lst-kix_list_14-0>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_14-4>li:before {
      content: "o  "
  }
  
  ol.lst-kix_list_15-2 {
      list-style-type: none
  }
  
  ol.lst-kix_list_15-3 {
      list-style-type: none
  }
  
  ol.lst-kix_list_15-4 {
      list-style-type: none
  }
  
  ol.lst-kix_list_18-5.start {
      counter-reset: lst-ctn-kix_list_18-5 0
  }
  
  .lst-kix_list_14-5>li:before {
      content: "\0025aa   "
  }
  
  .lst-kix_list_14-7>li:before {
      content: "o  "
  }
  
  ol.lst-kix_list_15-5 {
      list-style-type: none
  }
  
  ol.lst-kix_list_15-0 {
      list-style-type: none
  }
  
  ol.lst-kix_list_13-4.start {
      counter-reset: lst-ctn-kix_list_13-4 0
  }
  
  .lst-kix_list_14-6>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  ol.lst-kix_list_15-1 {
      list-style-type: none
  }
  
  .lst-kix_list_13-0>li {
      counter-increment: lst-ctn-kix_list_13-0
  }
  
  .lst-kix_list_17-0>li {
      counter-increment: lst-ctn-kix_list_17-0
  }
  
  .lst-kix_list_9-0>li {
      counter-increment: lst-ctn-kix_list_9-0
  }
  
  ol.lst-kix_list_2-3.start {
      counter-reset: lst-ctn-kix_list_2-3 0
  }
  
  .lst-kix_list_14-2>li:before {
      content: "\0025aa   "
  }
  
  .lst-kix_list_4-3>li {
      counter-increment: lst-ctn-kix_list_4-3
  }
  
  ol.lst-kix_list_17-1.start {
      counter-reset: lst-ctn-kix_list_17-1 0
  }
  
  .lst-kix_list_14-8>li:before {
      content: "\0025aa   "
  }
  
  .lst-kix_list_18-8>li {
      counter-increment: lst-ctn-kix_list_18-8
  }
  
  ol.lst-kix_list_8-8.start {
      counter-reset: lst-ctn-kix_list_8-8 0
  }
  
  ol.lst-kix_list_15-5.start {
      counter-reset: lst-ctn-kix_list_15-5 0
  }
  
  .lst-kix_list_5-0>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  ol.lst-kix_list_16-5 {
      list-style-type: none
  }
  
  ol.lst-kix_list_16-6 {
      list-style-type: none
  }
  
  ol.lst-kix_list_16-7 {
      list-style-type: none
  }
  
  ol.lst-kix_list_16-8 {
      list-style-type: none
  }
  
  .lst-kix_list_5-3>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  ol.lst-kix_list_9-5.start {
      counter-reset: lst-ctn-kix_list_9-5 0
  }
  
  ol.lst-kix_list_16-1 {
      list-style-type: none
  }
  
  ol.lst-kix_list_16-2 {
      list-style-type: none
  }
  
  .lst-kix_list_5-2>li:before {
      content: "\0025a0   "
  }
  
  ol.lst-kix_list_16-3 {
      list-style-type: none
  }
  
  .lst-kix_list_8-3>li {
      counter-increment: lst-ctn-kix_list_8-3
  }
  
  ol.lst-kix_list_16-4 {
      list-style-type: none
  }
  
  .lst-kix_list_5-1>li:before {
      content: "\0025cb   ";
      transform: scale(0.5);
  }
  
  ol.lst-kix_list_18-0.start {
      counter-reset: lst-ctn-kix_list_18-0 0
  }
  
  ol.lst-kix_list_16-0 {
      list-style-type: none
  }
  
  .lst-kix_list_5-7>li:before {
      content: "\0025cb   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_5-6>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_5-8>li:before {
      content: "\0025a0   "
  }
  
  .lst-kix_list_9-4>li {
      counter-increment: lst-ctn-kix_list_9-4
  }
  
  .lst-kix_list_5-4>li:before {
      content: "\0025cb   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_5-5>li:before {
      content: "\0025a0   "
  }
  
  ol.lst-kix_list_16-4.start {
      counter-reset: lst-ctn-kix_list_16-4 0
  }
  
  ol.lst-kix_list_12-5.start {
      counter-reset: lst-ctn-kix_list_12-5 0
  }
  
  .lst-kix_list_6-1>li:before {
      content: "o  "
  }
  
  .lst-kix_list_6-3>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_6-0>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_6-4>li:before {
      content: "o  "
  }
  
  .lst-kix_list_6-2>li:before {
      content: "\0025aa   "
  }
  
  ol.lst-kix_list_15-0.start {
      counter-reset: lst-ctn-kix_list_15-0 0
  }
  
  .lst-kix_list_2-5>li {
      counter-increment: lst-ctn-kix_list_2-5
  }
  
  .lst-kix_list_2-8>li {
      counter-increment: lst-ctn-kix_list_2-8
  }
  
  .lst-kix_list_6-8>li:before {
      content: "\0025aa   "
  }
  
  .lst-kix_list_6-5>li:before {
      content: "\0025aa   "
  }
  
  .lst-kix_list_6-7>li:before {
      content: "o  "
  }
  
  .lst-kix_list_6-6>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  ol.lst-kix_list_17-8 {
      list-style-type: none
  }
  
  .lst-kix_list_7-4>li:before {
      content: "o  "
  }
  
  .lst-kix_list_7-6>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  ol.lst-kix_list_17-4 {
      list-style-type: none
  }
  
  .lst-kix_list_18-5>li {
      counter-increment: lst-ctn-kix_list_18-5
  }
  
  ol.lst-kix_list_19-7.start {
      counter-reset: lst-ctn-kix_list_19-7 0
  }
  
  ol.lst-kix_list_17-5 {
      list-style-type: none
  }
  
  .lst-kix_list_15-5>li {
      counter-increment: lst-ctn-kix_list_15-5
  }
  
  ol.lst-kix_list_17-6 {
      list-style-type: none
  }
  
  ol.lst-kix_list_17-7 {
      list-style-type: none
  }
  
  ol.lst-kix_list_17-0 {
      list-style-type: none
  }
  
  ol.lst-kix_list_17-1 {
      list-style-type: none
  }
  
  ol.lst-kix_list_17-2 {
      list-style-type: none
  }
  
  .lst-kix_list_7-2>li:before {
      content: "\0025aa   "
  }
  
  ol.lst-kix_list_17-3 {
      list-style-type: none
  }
  
  .lst-kix_list_8-6>li {
      counter-increment: lst-ctn-kix_list_8-6
  }
  
  .lst-kix_list_12-6>li {
      counter-increment: lst-ctn-kix_list_12-6
  }
  
  ol.lst-kix_list_4-6.start {
      counter-reset: lst-ctn-kix_list_4-6 0
  }
  
  ol.lst-kix_list_9-7 {
      list-style-type: none
  }
  
  ol.lst-kix_list_9-8 {
      list-style-type: none
  }
  
  .lst-kix_list_13-7>li:before {
      content: "" counter(lst-ctn-kix_list_13-7, lower-latin) ". "
  }
  
  ol.lst-kix_list_9-3 {
      list-style-type: none
  }
  
  ol.lst-kix_list_9-4 {
      list-style-type: none
  }
  
  ol.lst-kix_list_9-5 {
      list-style-type: none
  }
  
  .lst-kix_list_7-8>li:before {
      content: "\0025aa   "
  }
  
  ol.lst-kix_list_9-6 {
      list-style-type: none
  }
  
  .lst-kix_list_15-6>li {
      counter-increment: lst-ctn-kix_list_15-6
  }
  
  .lst-kix_list_4-7>li {
      counter-increment: lst-ctn-kix_list_4-7
  }
  
  ol.lst-kix_list_9-0 {
      list-style-type: none
  }
  
  ol.lst-kix_list_9-1 {
      list-style-type: none
  }
  
  ol.lst-kix_list_9-2 {
      list-style-type: none
  }
  
  ol.lst-kix_list_2-5.start {
      counter-reset: lst-ctn-kix_list_2-5 0
  }
  
  .lst-kix_list_15-5>li:before {
      content: "" counter(lst-ctn-kix_list_15-5, lower-roman) ". "
  }
  
  .lst-kix_list_9-8>li {
      counter-increment: lst-ctn-kix_list_9-8
  }
  
  .lst-kix_list_13-4>li {
      counter-increment: lst-ctn-kix_list_13-4
  }
  
  .lst-kix_list_4-1>li:before {
      content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ". "
  }
  
  .lst-kix_list_15-7>li:before {
      content: "" counter(lst-ctn-kix_list_15-7, lower-latin) ". "
  }
  
  .lst-kix_list_17-7>li {
      counter-increment: lst-ctn-kix_list_17-7
  }
  
  .lst-kix_list_4-3>li:before {
      content: "" counter(lst-ctn-kix_list_4-3, decimal) ". "
  }
  
  .lst-kix_list_4-5>li:before {
      content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". "
  }
  
  .lst-kix_list_15-1>li:before {
      content: "" counter(lst-ctn-kix_list_15-1, lower-latin) ". "
  }
  
  ol.lst-kix_list_18-3.start {
      counter-reset: lst-ctn-kix_list_18-3 0
  }
  
  .lst-kix_list_15-3>li:before {
      content: "" counter(lst-ctn-kix_list_15-3, decimal) ". "
  }
  
  ol.lst-kix_list_18-7 {
      list-style-type: none
  }
  
  ol.lst-kix_list_4-4.start {
      counter-reset: lst-ctn-kix_list_4-4 0
  }
  
  ol.lst-kix_list_18-8 {
      list-style-type: none
  }
  
  .lst-kix_list_16-2>li {
      counter-increment: lst-ctn-kix_list_16-2
  }
  
  ol.lst-kix_list_18-3 {
      list-style-type: none
  }
  
  ol.lst-kix_list_18-4 {
      list-style-type: none
  }
  
  ol.lst-kix_list_18-5 {
      list-style-type: none
  }
  
  ol.lst-kix_list_18-6 {
      list-style-type: none
  }
  
  ol.lst-kix_list_9-2.start {
      counter-reset: lst-ctn-kix_list_9-2 0
  }
  
  ol.lst-kix_list_16-7.start {
      counter-reset: lst-ctn-kix_list_16-7 0
  }
  
  ol.lst-kix_list_18-0 {
      list-style-type: none
  }
  
  ol.lst-kix_list_18-1 {
      list-style-type: none
  }
  
  .lst-kix_list_9-3>li {
      counter-increment: lst-ctn-kix_list_9-3
  }
  
  ol.lst-kix_list_18-2 {
      list-style-type: none
  }
  
  ol.lst-kix_list_15-2.start {
      counter-reset: lst-ctn-kix_list_15-2 0
  }
  
  .lst-kix_list_19-2>li {
      counter-increment: lst-ctn-kix_list_19-2
  }
  
  ol.lst-kix_list_2-8.start {
      counter-reset: lst-ctn-kix_list_2-8 0
  }
  
  ol.lst-kix_list_8-8 {
      list-style-type: none
  }
  
  .lst-kix_list_12-3>li:before {
      content: "" counter(lst-ctn-kix_list_12-3, decimal) ". "
  }
  
  ol.lst-kix_list_8-4 {
      list-style-type: none
  }
  
  .lst-kix_list_12-1>li:before {
      content: "" counter(lst-ctn-kix_list_12-1, lower-latin) ". "
  }
  
  ol.lst-kix_list_8-5 {
      list-style-type: none
  }
  
  ol.lst-kix_list_8-6 {
      list-style-type: none
  }
  
  ol.lst-kix_list_8-7 {
      list-style-type: none
  }
  
  ol.lst-kix_list_8-0 {
      list-style-type: none
  }
  
  .lst-kix_list_16-3>li {
      counter-increment: lst-ctn-kix_list_16-3
  }
  
  ol.lst-kix_list_8-1 {
      list-style-type: none
  }
  
  ol.lst-kix_list_8-2 {
      list-style-type: none
  }
  
  .lst-kix_list_13-3>li {
      counter-increment: lst-ctn-kix_list_13-3
  }
  
  ol.lst-kix_list_13-6.start {
      counter-reset: lst-ctn-kix_list_13-6 0
  }
  
  ol.lst-kix_list_8-3 {
      list-style-type: none
  }
  
  ol.lst-kix_list_15-3.start {
      counter-reset: lst-ctn-kix_list_15-3 0
  }
  
  .lst-kix_list_13-3>li:before {
      content: "" counter(lst-ctn-kix_list_13-3, decimal) ". "
  }
  
  .lst-kix_list_13-5>li:before {
      content: "" counter(lst-ctn-kix_list_13-5, lower-roman) ". "
  }
  
  .lst-kix_list_12-5>li:before {
      content: "" counter(lst-ctn-kix_list_12-5, lower-roman) ". "
  }
  
  ol.lst-kix_list_13-7.start {
      counter-reset: lst-ctn-kix_list_13-7 0
  }
  
  .lst-kix_list_18-4>li {
      counter-increment: lst-ctn-kix_list_18-4
  }
  
  ol.lst-kix_list_12-2.start {
      counter-reset: lst-ctn-kix_list_12-2 0
  }
  
  .lst-kix_list_12-7>li:before {
      content: "" counter(lst-ctn-kix_list_12-7, lower-latin) ". "
  }
  
  .lst-kix_list_4-2>li {
      counter-increment: lst-ctn-kix_list_4-2
  }
  
  .lst-kix_list_13-1>li:before {
      content: "" counter(lst-ctn-kix_list_13-1, lower-latin) ". "
  }
  
  ol.lst-kix_list_19-0.start {
      counter-reset: lst-ctn-kix_list_19-0 0
  }
  
  ol.lst-kix_list_2-6.start {
      counter-reset: lst-ctn-kix_list_2-6 0
  }
  
  .lst-kix_list_3-0>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  ul.lst-kix_list_5-7 {
      list-style-type: none
  }
  
  ol.lst-kix_list_13-1.start {
      counter-reset: lst-ctn-kix_list_13-1 0
  }
  
  ul.lst-kix_list_5-8 {
      list-style-type: none
  }
  
  ul.lst-kix_list_5-5 {
      list-style-type: none
  }
  
  ul.lst-kix_list_5-6 {
      list-style-type: none
  }
  
  .lst-kix_list_16-0>li {
      counter-increment: lst-ctn-kix_list_16-0
  }
  
  .lst-kix_list_4-0>li {
      counter-increment: lst-ctn-kix_list_4-0
  }
  
  .lst-kix_list_8-0>li {
      counter-increment: lst-ctn-kix_list_8-0
  }
  
  ul.lst-kix_list_5-0 {
      list-style-type: none
  }
  
  .lst-kix_list_3-4>li:before {
      content: "\0025cb   ";
      transform: scale(0.5);
  }
  
  ol.lst-kix_list_18-2.start {
      counter-reset: lst-ctn-kix_list_18-2 0
  }
  
  ul.lst-kix_list_5-3 {
      list-style-type: none
  }
  
  .lst-kix_list_3-3>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  ul.lst-kix_list_5-4 {
      list-style-type: none
  }
  
  ul.lst-kix_list_5-1 {
      list-style-type: none
  }
  
  .lst-kix_list_8-0>li:before {
      content: "" counter(lst-ctn-kix_list_8-0, upper-latin) ". "
  }
  
  ul.lst-kix_list_5-2 {
      list-style-type: none
  }
  
  .lst-kix_list_8-7>li:before {
      content: "" counter(lst-ctn-kix_list_8-7, lower-latin) ". "
  }
  
  .lst-kix_list_3-8>li:before {
      content: "\0025a0   "
  }
  
  .lst-kix_list_13-1>li {
      counter-increment: lst-ctn-kix_list_13-1
  }
  
  ol.lst-kix_list_15-8.start {
      counter-reset: lst-ctn-kix_list_15-8 0
  }
  
  .lst-kix_list_8-3>li:before {
      content: "" counter(lst-ctn-kix_list_8-3, decimal) ". "
  }
  
  .lst-kix_list_3-7>li:before {
      content: "\0025cb   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_8-4>li:before {
      content: "" counter(lst-ctn-kix_list_8-4, lower-latin) ". "
  }
  
  .lst-kix_list_19-1>li {
      counter-increment: lst-ctn-kix_list_19-1
  }
  
  ol.lst-kix_list_8-5.start {
      counter-reset: lst-ctn-kix_list_8-5 0
  }
  
  .lst-kix_list_17-1>li {
      counter-increment: lst-ctn-kix_list_17-1
  }
  
  .lst-kix_list_11-1>li:before {
      content: "\0025cb   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_11-0>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  ol.lst-kix_list_9-3.start {
      counter-reset: lst-ctn-kix_list_9-3 0
  }
  
  .lst-kix_list_8-8>li:before {
      content: "" counter(lst-ctn-kix_list_8-8, lower-roman) ". "
  }
  
  ol.lst-kix_list_2-2 {
      list-style-type: none
  }
  
  .lst-kix_list_16-8>li:before {
      content: "" counter(lst-ctn-kix_list_16-8, decimal) ". "
  }
  
  ol.lst-kix_list_2-3 {
      list-style-type: none
  }
  
  ol.lst-kix_list_2-4 {
      list-style-type: none
  }
  
  .lst-kix_list_16-7>li:before {
      content: "" counter(lst-ctn-kix_list_16-7, decimal) ". "
  }
  
  ol.lst-kix_list_2-5 {
      list-style-type: none
  }
  
  .lst-kix_list_17-8>li {
      counter-increment: lst-ctn-kix_list_17-8
  }
  
  ol.lst-kix_list_2-0 {
      list-style-type: none
  }
  
  ol.lst-kix_list_2-1 {
      list-style-type: none
  }
  
  ol.lst-kix_list_19-5.start {
      counter-reset: lst-ctn-kix_list_19-5 0
  }
  
  .lst-kix_list_4-8>li:before {
      content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". "
  }
  
  ol.lst-kix_list_12-5 {
      list-style-type: none
  }
  
  ol.lst-kix_list_12-6 {
      list-style-type: none
  }
  
  .lst-kix_list_4-7>li:before {
      content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". "
  }
  
  ol.lst-kix_list_12-7 {
      list-style-type: none
  }
  
  .lst-kix_list_17-0>li:before {
      content: "" counter(lst-ctn-kix_list_17-0, decimal) ". "
  }
  
  ol.lst-kix_list_12-8 {
      list-style-type: none
  }
  
  ol.lst-kix_list_12-1 {
      list-style-type: none
  }
  
  ol.lst-kix_list_12-2 {
      list-style-type: none
  }
  
  ol.lst-kix_list_12-3 {
      list-style-type: none
  }
  
  ol.lst-kix_list_12-4 {
      list-style-type: none
  }
  
  .lst-kix_list_16-0>li:before {
      content: "" counter(lst-ctn-kix_list_16-0, decimal) ". "
  }
  
  ol.lst-kix_list_4-8.start {
      counter-reset: lst-ctn-kix_list_4-8 0
  }
  
  .lst-kix_list_8-4>li {
      counter-increment: lst-ctn-kix_list_8-4
  }
  
  .lst-kix_list_16-4>li:before {
      content: "" counter(lst-ctn-kix_list_16-4, decimal) ". "
  }
  
  .lst-kix_list_16-3>li:before {
      content: "" counter(lst-ctn-kix_list_16-3, decimal) ". "
  }
  
  ol.lst-kix_list_2-6 {
      list-style-type: none
  }
  
  ol.lst-kix_list_2-7 {
      list-style-type: none
  }
  
  ol.lst-kix_list_2-8 {
      list-style-type: none
  }
  
  ol.lst-kix_list_18-1.start {
      counter-reset: lst-ctn-kix_list_18-1 0
  }
  
  ol.lst-kix_list_8-6.start {
      counter-reset: lst-ctn-kix_list_8-6 0
  }
  
  .lst-kix_list_17-7>li:before {
      content: "" counter(lst-ctn-kix_list_17-7, decimal) ". "
  }
  
  .lst-kix_list_16-7>li {
      counter-increment: lst-ctn-kix_list_16-7
  }
  
  .lst-kix_list_17-8>li:before {
      content: "" counter(lst-ctn-kix_list_17-8, decimal) ". "
  }
  
  ul.lst-kix_list_12-0 {
      list-style-type: none
  }
  
  .lst-kix_list_17-3>li:before {
      content: "" counter(lst-ctn-kix_list_17-3, decimal) ". "
  }
  
  .lst-kix_list_17-4>li:before {
      content: "" counter(lst-ctn-kix_list_17-4, decimal) ". "
  }
  
  ol.lst-kix_list_8-0.start {
      counter-reset: lst-ctn-kix_list_8-0 0
  }
  
  .lst-kix_list_7-0>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_13-8>li {
      counter-increment: lst-ctn-kix_list_13-8
  }
  
  .lst-kix_list_2-2>li {
      counter-increment: lst-ctn-kix_list_2-2
  }
  
  ol.lst-kix_list_19-6.start {
      counter-reset: lst-ctn-kix_list_19-6 0
  }
  
  ol.lst-kix_list_4-7.start {
      counter-reset: lst-ctn-kix_list_4-7 0
  }
  
  .lst-kix_list_16-5>li {
      counter-increment: lst-ctn-kix_list_16-5
  }
  
  ol.lst-kix_list_9-7.start {
      counter-reset: lst-ctn-kix_list_9-7 0
  }
  
  ol.lst-kix_list_13-8 {
      list-style-type: none
  }
  
  .lst-kix_list_2-4>li:before {
      content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". "
  }
  
  .lst-kix_list_2-8>li:before {
      content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". "
  }
  
  ol.lst-kix_list_13-4 {
      list-style-type: none
  }
  
  ol.lst-kix_list_13-5 {
      list-style-type: none
  }
  
  ol.lst-kix_list_13-6 {
      list-style-type: none
  }
  
  ol.lst-kix_list_13-7 {
      list-style-type: none
  }
  
  ol.lst-kix_list_13-0 {
      list-style-type: none
  }
  
  ol.lst-kix_list_13-1 {
      list-style-type: none
  }
  
  ol.lst-kix_list_13-2 {
      list-style-type: none
  }
  
  ol.lst-kix_list_15-7.start {
      counter-reset: lst-ctn-kix_list_15-7 0
  }
  
  .lst-kix_list_7-3>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  ol.lst-kix_list_13-3 {
      list-style-type: none
  }
  
  ul.lst-kix_list_7-5 {
      list-style-type: none
  }
  
  .lst-kix_list_10-0>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  ul.lst-kix_list_7-6 {
      list-style-type: none
  }
  
  ul.lst-kix_list_7-3 {
      list-style-type: none
  }
  
  .lst-kix_list_9-7>li {
      counter-increment: lst-ctn-kix_list_9-7
  }
  
  ul.lst-kix_list_7-4 {
      list-style-type: none
  }
  
  .lst-kix_list_13-6>li {
      counter-increment: lst-ctn-kix_list_13-6
  }
  
  .lst-kix_list_13-8>li:before {
      content: "" counter(lst-ctn-kix_list_13-8, lower-roman) ". "
  }
  
  .lst-kix_list_18-3>li:before {
      content: "" counter(lst-ctn-kix_list_18-3, decimal) ". "
  }
  
  .lst-kix_list_18-7>li:before {
      content: "" counter(lst-ctn-kix_list_18-7, decimal) ". "
  }
  
  ul.lst-kix_list_7-7 {
      list-style-type: none
  }
  
  ul.lst-kix_list_7-8 {
      list-style-type: none
  }
  
  .lst-kix_list_18-6>li {
      counter-increment: lst-ctn-kix_list_18-6
  }
  
  ul.lst-kix_list_7-1 {
      list-style-type: none
  }
  
  .lst-kix_list_8-7>li {
      counter-increment: lst-ctn-kix_list_8-7
  }
  
  .lst-kix_list_19-6>li {
      counter-increment: lst-ctn-kix_list_19-6
  }
  
  ul.lst-kix_list_7-2 {
      list-style-type: none
  }
  
  ul.lst-kix_list_7-0 {
      list-style-type: none
  }
  
  .lst-kix_list_7-7>li:before {
      content: "o  "
  }
  
  ol.lst-kix_list_8-1.start {
      counter-reset: lst-ctn-kix_list_8-1 0
  }
  
  .lst-kix_list_15-4>li:before {
      content: "" counter(lst-ctn-kix_list_15-4, lower-latin) ". "
  }
  
  .lst-kix_list_9-5>li {
      counter-increment: lst-ctn-kix_list_9-5
  }
  
  ol.lst-kix_list_19-1.start {
      counter-reset: lst-ctn-kix_list_19-1 0
  }
  
  .lst-kix_list_10-4>li:before {
      content: "\0025cb   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_10-8>li:before {
      content: "\0025a0   "
  }
  
  .lst-kix_list_4-0>li:before {
      content: "" counter(lst-ctn-kix_list_4-0, decimal) ". "
  }
  
  .lst-kix_list_15-0>li:before {
      content: "" counter(lst-ctn-kix_list_15-0, upper-latin) ". "
  }
  
  .lst-kix_list_15-8>li:before {
      content: "" counter(lst-ctn-kix_list_15-8, lower-roman) ". "
  }
  
  ol.lst-kix_list_19-4.start {
      counter-reset: lst-ctn-kix_list_19-4 0
  }
  
  .lst-kix_list_15-7>li {
      counter-increment: lst-ctn-kix_list_15-7
  }
  
  .lst-kix_list_4-4>li:before {
      content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". "
  }
  
  ol.lst-kix_list_2-2.start {
      counter-reset: lst-ctn-kix_list_2-2 0
  }
  
  .lst-kix_list_19-4>li {
      counter-increment: lst-ctn-kix_list_19-4
  }
  
  .lst-kix_list_9-3>li:before {
      content: "" counter(lst-ctn-kix_list_9-3, decimal) ". "
  }
  
  .lst-kix_list_12-8>li {
      counter-increment: lst-ctn-kix_list_12-8
  }
  
  ol.lst-kix_list_13-2.start {
      counter-reset: lst-ctn-kix_list_13-2 0
  }
  
  ol.lst-kix_list_4-0 {
      list-style-type: none
  }
  
  ol.lst-kix_list_4-1 {
      list-style-type: none
  }
  
  ol.lst-kix_list_4-2 {
      list-style-type: none
  }
  
  ol.lst-kix_list_4-3 {
      list-style-type: none
  }
  
  .lst-kix_list_9-7>li:before {
      content: "" counter(lst-ctn-kix_list_9-7, lower-latin) ". "
  }
  
  .lst-kix_list_2-4>li {
      counter-increment: lst-ctn-kix_list_2-4
  }
  
  .lst-kix_list_11-4>li:before {
      content: "\0025cb   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_15-2>li {
      counter-increment: lst-ctn-kix_list_15-2
  }
  
  .lst-kix_list_12-4>li:before {
      content: "" counter(lst-ctn-kix_list_12-4, lower-latin) ". "
  }
  
  ul.lst-kix_list_6-6 {
      list-style-type: none
  }
  
  ul.lst-kix_list_6-7 {
      list-style-type: none
  }
  
  ul.lst-kix_list_6-4 {
      list-style-type: none
  }
  
  ul.lst-kix_list_6-5 {
      list-style-type: none
  }
  
  ul.lst-kix_list_6-8 {
      list-style-type: none
  }
  
  ol.lst-kix_list_4-8 {
      list-style-type: none
  }
  
  .lst-kix_list_1-0>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  ol.lst-kix_list_19-2.start {
      counter-reset: lst-ctn-kix_list_19-2 0
  }
  
  ol.lst-kix_list_4-4 {
      list-style-type: none
  }
  
  ul.lst-kix_list_6-2 {
      list-style-type: none
  }
  
  .lst-kix_list_11-8>li:before {
      content: "\0025a0   "
  }
  
  .lst-kix_list_12-3>li {
      counter-increment: lst-ctn-kix_list_12-3
  }
  
  ol.lst-kix_list_4-5 {
      list-style-type: none
  }
  
  ul.lst-kix_list_6-3 {
      list-style-type: none
  }
  
  ol.lst-kix_list_2-0.start {
      counter-reset: lst-ctn-kix_list_2-0 0
  }
  
  ol.lst-kix_list_4-6 {
      list-style-type: none
  }
  
  ul.lst-kix_list_6-0 {
      list-style-type: none
  }
  
  .lst-kix_list_12-0>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_17-3>li {
      counter-increment: lst-ctn-kix_list_17-3
  }
  
  ol.lst-kix_list_4-7 {
      list-style-type: none
  }
  
  ul.lst-kix_list_6-1 {
      list-style-type: none
  }
  
  ol.lst-kix_list_8-4.start {
      counter-reset: lst-ctn-kix_list_8-4 0
  }
  
  .lst-kix_list_1-4>li:before {
      content: "\0025cb   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_13-0>li:before {
      content: "" counter(lst-ctn-kix_list_13-0, upper-latin) ". "
  }
  
  ul.lst-kix_list_14-4 {
      list-style-type: none
  }
  
  ul.lst-kix_list_14-3 {
      list-style-type: none
  }
  
  ol.lst-kix_list_13-0.start {
      counter-reset: lst-ctn-kix_list_13-0 0
  }
  
  ul.lst-kix_list_14-2 {
      list-style-type: none
  }
  
  .lst-kix_list_13-4>li:before {
      content: "" counter(lst-ctn-kix_list_13-4, lower-latin) ". "
  }
  
  ul.lst-kix_list_14-1 {
      list-style-type: none
  }
  
  ul.lst-kix_list_14-0 {
      list-style-type: none
  }
  
  ol.lst-kix_list_19-3.start {
      counter-reset: lst-ctn-kix_list_19-3 0
  }
  
  ul.lst-kix_list_14-8 {
      list-style-type: none
  }
  
  ul.lst-kix_list_14-7 {
      list-style-type: none
  }
  
  .lst-kix_list_2-0>li:before {
      content: "" counter(lst-ctn-kix_list_2-0, upper-latin) ". "
  }
  
  ol.lst-kix_list_2-1.start {
      counter-reset: lst-ctn-kix_list_2-1 0
  }
  
  .lst-kix_list_18-1>li {
      counter-increment: lst-ctn-kix_list_18-1
  }
  
  ol.lst-kix_list_8-3.start {
      counter-reset: lst-ctn-kix_list_8-3 0
  }
  
  ul.lst-kix_list_14-6 {
      list-style-type: none
  }
  
  ul.lst-kix_list_14-5 {
      list-style-type: none
  }
  
  .lst-kix_list_4-5>li {
      counter-increment: lst-ctn-kix_list_4-5
  }
  
  ol.lst-kix_list_9-8.start {
      counter-reset: lst-ctn-kix_list_9-8 0
  }
  
  .lst-kix_list_1-8>li:before {
      content: "\0025a0   "
  }
  
  .lst-kix_list_12-8>li:before {
      content: "" counter(lst-ctn-kix_list_12-8, lower-roman) ". "
  }
  
  .lst-kix_list_8-2>li {
      counter-increment: lst-ctn-kix_list_8-2
  }
  
  .lst-kix_list_4-1>li {
      counter-increment: lst-ctn-kix_list_4-1
  }
  
  .lst-kix_list_19-0>li:before {
      content: "" counter(lst-ctn-kix_list_19-0, decimal) ". "
  }
  
  .lst-kix_list_19-1>li:before {
      content: "" counter(lst-ctn-kix_list_19-1, decimal) ". "
  }
  
  ol.lst-kix_list_17-7.start {
      counter-reset: lst-ctn-kix_list_17-7 0
  }
  
  ul.lst-kix_list_1-0 {
      list-style-type: none
  }
  
  ol.lst-kix_list_12-6.start {
      counter-reset: lst-ctn-kix_list_12-6 0
  }
  
  .lst-kix_list_19-4>li:before {
      content: "" counter(lst-ctn-kix_list_19-4, decimal) ". "
  }
  
  .lst-kix_list_8-1>li {
      counter-increment: lst-ctn-kix_list_8-1
  }
  
  ol.lst-kix_list_8-2.start {
      counter-reset: lst-ctn-kix_list_8-2 0
  }
  
  .lst-kix_list_19-2>li:before {
      content: "" counter(lst-ctn-kix_list_19-2, decimal) ". "
  }
  
  .lst-kix_list_19-3>li:before {
      content: "" counter(lst-ctn-kix_list_19-3, decimal) ". "
  }
  
  .lst-kix_list_15-0>li {
      counter-increment: lst-ctn-kix_list_15-0
  }
  
  ul.lst-kix_list_1-3 {
      list-style-type: none
  }
  
  ul.lst-kix_list_1-4 {
      list-style-type: none
  }
  
  .lst-kix_list_19-0>li {
      counter-increment: lst-ctn-kix_list_19-0
  }
  
  ul.lst-kix_list_1-1 {
      list-style-type: none
  }
  
  ul.lst-kix_list_1-2 {
      list-style-type: none
  }
  
  ul.lst-kix_list_1-7 {
      list-style-type: none
  }
  
  ul.lst-kix_list_1-8 {
      list-style-type: none
  }
  
  ul.lst-kix_list_1-5 {
      list-style-type: none
  }
  
  ul.lst-kix_list_1-6 {
      list-style-type: none
  }
  
  ol.lst-kix_list_9-6.start {
      counter-reset: lst-ctn-kix_list_9-6 0
  }
  
  ol.lst-kix_list_16-3.start {
      counter-reset: lst-ctn-kix_list_16-3 0
  }
  
  .lst-kix_list_2-3>li {
      counter-increment: lst-ctn-kix_list_2-3
  }
  
  ol.lst-kix_list_19-8.start {
      counter-reset: lst-ctn-kix_list_19-8 0
  }
  
  ol.lst-kix_list_4-5.start {
      counter-reset: lst-ctn-kix_list_4-5 0
  }
  
  .lst-kix_list_19-8>li:before {
      content: "" counter(lst-ctn-kix_list_19-8, decimal) ". "
  }
  
  .lst-kix_list_19-5>li:before {
      content: "" counter(lst-ctn-kix_list_19-5, decimal) ". "
  }
  
  .lst-kix_list_19-6>li:before {
      content: "" counter(lst-ctn-kix_list_19-6, decimal) ". "
  }
  
  .lst-kix_list_19-7>li:before {
      content: "" counter(lst-ctn-kix_list_19-7, decimal) ". "
  }
  
  .lst-kix_list_9-2>li {
      counter-increment: lst-ctn-kix_list_9-2
  }
  
  ol.lst-kix_list_8-7.start {
      counter-reset: lst-ctn-kix_list_8-7 0
  }
  
  .lst-kix_list_17-2>li {
      counter-increment: lst-ctn-kix_list_17-2
  }
  
  ol.lst-kix_list_17-2.start {
      counter-reset: lst-ctn-kix_list_17-2 0
  }
  
  .lst-kix_list_13-2>li {
      counter-increment: lst-ctn-kix_list_13-2
  }
  
  .lst-kix_list_19-7>li {
      counter-increment: lst-ctn-kix_list_19-7
  }
  
  ol.lst-kix_list_15-6.start {
      counter-reset: lst-ctn-kix_list_15-6 0
  }
  
  .lst-kix_list_18-3>li {
      counter-increment: lst-ctn-kix_list_18-3
  }
  
  .lst-kix_list_12-1>li {
      counter-increment: lst-ctn-kix_list_12-1
  }
  
  .lst-kix_list_18-0>li:before {
      content: "" counter(lst-ctn-kix_list_18-0, decimal) ". "
  }
  
  ol.lst-kix_list_13-3.start {
      counter-reset: lst-ctn-kix_list_13-3 0
  }
  
  .lst-kix_list_18-1>li:before {
      content: "" counter(lst-ctn-kix_list_18-1, decimal) ". "
  }
  
  .lst-kix_list_18-2>li:before {
      content: "" counter(lst-ctn-kix_list_18-2, decimal) ". "
  }
  
  ol.lst-kix_list_4-0.start {
      counter-reset: lst-ctn-kix_list_4-0 0
  }
  
  li.li-bullet-2:before {
      margin-left: -18pt;
      white-space: nowrap;
      display: inline-block;
      min-width: 18pt
  }
  
  .lst-kix_list_16-1>li {
      counter-increment: lst-ctn-kix_list_16-1
  }
  
  ol.lst-kix_list_2-4.start {
      counter-reset: lst-ctn-kix_list_2-4 0
  }
  
  .lst-kix_list_2-7>li:before {
      content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". "
  }
  
  .lst-kix_list_2-7>li {
      counter-increment: lst-ctn-kix_list_2-7
  }
  
  .lst-kix_list_2-5>li:before {
      content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". "
  }
  
  .lst-kix_list_17-5>li {
      counter-increment: lst-ctn-kix_list_17-5
  }
  
  ol.lst-kix_list_17-0.start {
      counter-reset: lst-ctn-kix_list_17-0 0
  }
  
  .lst-kix_list_18-6>li:before {
      content: "" counter(lst-ctn-kix_list_18-6, decimal) ". "
  }
  
  ol.lst-kix_list_9-4.start {
      counter-reset: lst-ctn-kix_list_9-4 0
  }
  
  ul.lst-kix_list_3-7 {
      list-style-type: none
  }
  
  ul.lst-kix_list_3-8 {
      list-style-type: none
  }
  
  .lst-kix_list_10-1>li:before {
      content: "\0025cb   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_18-4>li:before {
      content: "" counter(lst-ctn-kix_list_18-4, decimal) ". "
  }
  
  .lst-kix_list_18-8>li:before {
      content: "" counter(lst-ctn-kix_list_18-8, decimal) ". "
  }
  
  ul.lst-kix_list_3-1 {
      list-style-type: none
  }
  
  .lst-kix_list_17-6>li {
      counter-increment: lst-ctn-kix_list_17-6
  }
  
  ul.lst-kix_list_3-2 {
      list-style-type: none
  }
  
  ol.lst-kix_list_15-1.start {
      counter-reset: lst-ctn-kix_list_15-1 0
  }
  
  ol.lst-kix_list_15-4.start {
      counter-reset: lst-ctn-kix_list_15-4 0
  }
  
  ul.lst-kix_list_3-0 {
      list-style-type: none
  }
  
  ol.lst-kix_list_4-3.start {
      counter-reset: lst-ctn-kix_list_4-3 0
  }
  
  ul.lst-kix_list_3-5 {
      list-style-type: none
  }
  
  ul.lst-kix_list_3-6 {
      list-style-type: none
  }
  
  ul.lst-kix_list_3-3 {
      list-style-type: none
  }
  
  ul.lst-kix_list_3-4 {
      list-style-type: none
  }
  
  .lst-kix_list_10-7>li:before {
      content: "\0025cb   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_10-5>li:before {
      content: "\0025a0   "
  }
  
  ol.lst-kix_list_13-5.start {
      counter-reset: lst-ctn-kix_list_13-5 0
  }
  
  ol.lst-kix_list_18-6.start {
      counter-reset: lst-ctn-kix_list_18-6 0
  }
  
  li.li-bullet-1:before {
      margin-left: -18pt;
      white-space: nowrap;
      display: inline-block;
      min-width: 18pt
  }
  
  .lst-kix_list_10-3>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  ul.lst-kix_list_11-7 {
      list-style-type: none
  }
  
  .lst-kix_list_15-4>li {
      counter-increment: lst-ctn-kix_list_15-4
  }
  
  ul.lst-kix_list_11-6 {
      list-style-type: none
  }
  
  .lst-kix_list_2-6>li {
      counter-increment: lst-ctn-kix_list_2-6
  }
  
  ul.lst-kix_list_11-5 {
      list-style-type: none
  }
  
  ul.lst-kix_list_11-4 {
      list-style-type: none
  }
  
  ul.lst-kix_list_11-3 {
      list-style-type: none
  }
  
  ol.lst-kix_list_13-8.start {
      counter-reset: lst-ctn-kix_list_13-8 0
  }
  
  ul.lst-kix_list_11-2 {
      list-style-type: none
  }
  
  ul.lst-kix_list_11-1 {
      list-style-type: none
  }
  
  ul.lst-kix_list_11-0 {
      list-style-type: none
  }
  
  .lst-kix_list_9-2>li:before {
      content: "" counter(lst-ctn-kix_list_9-2, lower-roman) ". "
  }
  
  ul.lst-kix_list_11-8 {
      list-style-type: none
  }
  
  .lst-kix_list_12-5>li {
      counter-increment: lst-ctn-kix_list_12-5
  }
  
  .lst-kix_list_9-0>li:before {
      content: "" counter(lst-ctn-kix_list_9-0, upper-latin) ". "
  }
  
  .lst-kix_list_16-8>li {
      counter-increment: lst-ctn-kix_list_16-8
  }
  
  .lst-kix_list_9-6>li:before {
      content: "" counter(lst-ctn-kix_list_9-6, decimal) ". "
  }
  
  .lst-kix_list_9-4>li:before {
      content: "" counter(lst-ctn-kix_list_9-4, lower-latin) ". "
  }
  
  .lst-kix_list_11-3>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  ol.lst-kix_list_18-4.start {
      counter-reset: lst-ctn-kix_list_18-4 0
  }
  
  ol.lst-kix_list_12-1.start {
      counter-reset: lst-ctn-kix_list_12-1 0
  }
  
  .lst-kix_list_11-5>li:before {
      content: "\0025a0   "
  }
  
  .lst-kix_list_9-8>li:before {
      content: "" counter(lst-ctn-kix_list_9-8, lower-roman) ". "
  }
  
  .lst-kix_list_1-1>li:before {
      content: "\0025cb   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_11-7>li:before {
      content: "\0025cb   ";
      transform: scale(0.5);
  }
  
  ul.lst-kix_list_10-0 {
      list-style-type: none
  }
  
  .lst-kix_list_8-5>li {
      counter-increment: lst-ctn-kix_list_8-5
  }
  
  .lst-kix_list_1-3>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  ol.lst-kix_list_16-8.start {
      counter-reset: lst-ctn-kix_list_16-8 0
  }
  
  ul.lst-kix_list_10-8 {
      list-style-type: none
  }
  
  .lst-kix_list_4-8>li {
      counter-increment: lst-ctn-kix_list_4-8
  }
  
  ul.lst-kix_list_10-7 {
      list-style-type: none
  }
  
  .lst-kix_list_1-7>li:before {
      content: "\0025cb   ";
      transform: scale(0.5);
  }
  
  ul.lst-kix_list_10-6 {
      list-style-type: none
  }
  
  ol.lst-kix_list_2-7.start {
      counter-reset: lst-ctn-kix_list_2-7 0
  }
  
  ul.lst-kix_list_10-5 {
      list-style-type: none
  }
  
  ul.lst-kix_list_10-4 {
      list-style-type: none
  }
  
  ul.lst-kix_list_10-3 {
      list-style-type: none
  }
  
  .lst-kix_list_1-5>li:before {
      content: "\0025a0   "
  }
  
  ul.lst-kix_list_10-2 {
      list-style-type: none
  }
  
  ul.lst-kix_list_10-1 {
      list-style-type: none
  }
  
  ol.lst-kix_list_9-1.start {
      counter-reset: lst-ctn-kix_list_9-1 0
  }
  
  .lst-kix_list_2-1>li:before {
      content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". "
  }
  
  .lst-kix_list_19-8>li {
      counter-increment: lst-ctn-kix_list_19-8
  }
  
  .lst-kix_list_2-3>li:before {
      content: "" counter(lst-ctn-kix_list_2-3, decimal) ". "
  }
  
  ol.lst-kix_list_19-6 {
      list-style-type: none
  }
  
  ol.lst-kix_list_19-7 {
      list-style-type: none
  }
  
  ol.lst-kix_list_19-8 {
      list-style-type: none
  }
  
  ol.lst-kix_list_19-2 {
      list-style-type: none
  }
  
  ol.lst-kix_list_19-3 {
      list-style-type: none
  }
  
  ol.lst-kix_list_19-4 {
      list-style-type: none
  }
  
  ol.lst-kix_list_19-5 {
      list-style-type: none
  }
  
  ol.lst-kix_list_19-0 {
      list-style-type: none
  }
  
  .lst-kix_list_9-1>li {
      counter-increment: lst-ctn-kix_list_9-1
  }
  
  ol.lst-kix_list_19-1 {
      list-style-type: none
  }
  
  .lst-kix_list_3-1>li:before {
      content: "\0025cb   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_3-2>li:before {
      content: "\0025a0   "
  }
  
  .lst-kix_list_8-1>li:before {
      content: "" counter(lst-ctn-kix_list_8-1, lower-latin) ". "
  }
  
  ol.lst-kix_list_17-4.start {
      counter-reset: lst-ctn-kix_list_17-4 0
  }
  
  .lst-kix_list_8-2>li:before {
      content: "" counter(lst-ctn-kix_list_8-2, lower-roman) ". "
  }
  
  .lst-kix_list_3-5>li:before {
      content: "\0025a0   "
  }
  
  .lst-kix_list_18-0>li {
      counter-increment: lst-ctn-kix_list_18-0
  }
  
  ol.lst-kix_list_12-3.start {
      counter-reset: lst-ctn-kix_list_12-3 0
  }
  
  .lst-kix_list_8-5>li:before {
      content: "" counter(lst-ctn-kix_list_8-5, lower-roman) ". "
  }
  
  .lst-kix_list_8-6>li:before {
      content: "" counter(lst-ctn-kix_list_8-6, decimal) ". "
  }
  
  .lst-kix_list_2-0>li {
      counter-increment: lst-ctn-kix_list_2-0
  }
  
  .lst-kix_list_15-1>li {
      counter-increment: lst-ctn-kix_list_15-1
  }
  
  .lst-kix_list_3-6>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  ol.lst-kix_list_16-6.start {
      counter-reset: lst-ctn-kix_list_16-6 0
  }
  
  .lst-kix_list_11-2>li:before {
      content: "\0025a0   "
  }
  
  ol.lst-kix_list_4-2.start {
      counter-reset: lst-ctn-kix_list_4-2 0
  }
  
  ol.lst-kix_list_16-0.start {
      counter-reset: lst-ctn-kix_list_16-0 0
  }
  
  ol.lst-kix_list_18-7.start {
      counter-reset: lst-ctn-kix_list_18-7 0
  }
  
  ol.lst-kix_list_12-4.start {
      counter-reset: lst-ctn-kix_list_12-4 0
  }
  
  .lst-kix_list_16-6>li:before {
      content: "" counter(lst-ctn-kix_list_16-6, decimal) ". "
  }
  
  .lst-kix_list_4-4>li {
      counter-increment: lst-ctn-kix_list_4-4
  }
  
  .lst-kix_list_17-1>li:before {
      content: "" counter(lst-ctn-kix_list_17-1, decimal) ". "
  }
  
  .lst-kix_list_18-2>li {
      counter-increment: lst-ctn-kix_list_18-2
  }
  
  .lst-kix_list_16-1>li:before {
      content: "" counter(lst-ctn-kix_list_16-1, decimal) ". "
  }
  
  ol.lst-kix_list_4-1.start {
      counter-reset: lst-ctn-kix_list_4-1 0
  }
  
  .lst-kix_list_16-2>li:before {
      content: "" counter(lst-ctn-kix_list_16-2, decimal) ". "
  }
  
  .lst-kix_list_16-5>li:before {
      content: "" counter(lst-ctn-kix_list_16-5, decimal) ". "
  }
  
  .lst-kix_list_19-3>li {
      counter-increment: lst-ctn-kix_list_19-3
  }
  
  .lst-kix_list_15-3>li {
      counter-increment: lst-ctn-kix_list_15-3
  }
  
  .lst-kix_list_12-4>li {
      counter-increment: lst-ctn-kix_list_12-4
  }
  
  ol.lst-kix_list_18-8.start {
      counter-reset: lst-ctn-kix_list_18-8 0
  }
  
  .lst-kix_list_16-4>li {
      counter-increment: lst-ctn-kix_list_16-4
  }
  
  .lst-kix_list_12-7>li {
      counter-increment: lst-ctn-kix_list_12-7
  }
  
  .lst-kix_list_17-2>li:before {
      content: "" counter(lst-ctn-kix_list_17-2, decimal) ". "
  }
  
  ol.lst-kix_list_16-5.start {
      counter-reset: lst-ctn-kix_list_16-5 0
  }
  
  ol.lst-kix_list_17-3.start {
      counter-reset: lst-ctn-kix_list_17-3 0
  }
  
  .lst-kix_list_17-6>li:before {
      content: "" counter(lst-ctn-kix_list_17-6, decimal) ". "
  }
  
  .lst-kix_list_17-5>li:before {
      content: "" counter(lst-ctn-kix_list_17-5, decimal) ". "
  }
  
  .lst-kix_list_2-6>li:before {
      content: "" counter(lst-ctn-kix_list_2-6, decimal) ". "
  }
  
  ol.lst-kix_list_16-2.start {
      counter-reset: lst-ctn-kix_list_16-2 0
  }
  
  .lst-kix_list_7-1>li:before {
      content: "o  "
  }
  
  .lst-kix_list_7-5>li:before {
      content: "\0025aa   "
  }
  
  .lst-kix_list_13-5>li {
      counter-increment: lst-ctn-kix_list_13-5
  }
  
  .lst-kix_list_9-6>li {
      counter-increment: lst-ctn-kix_list_9-6
  }
  
  .lst-kix_list_19-5>li {
      counter-increment: lst-ctn-kix_list_19-5
  }
  
  .lst-kix_list_18-5>li:before {
      content: "" counter(lst-ctn-kix_list_18-5, decimal) ". "
  }
  
  .lst-kix_list_13-6>li:before {
      content: "" counter(lst-ctn-kix_list_13-6, decimal) ". "
  }
  
  .lst-kix_list_16-6>li {
      counter-increment: lst-ctn-kix_list_16-6
  }
  
  .lst-kix_list_15-6>li:before {
      content: "" counter(lst-ctn-kix_list_15-6, decimal) ". "
  }
  
  ol.lst-kix_list_17-8.start {
      counter-reset: lst-ctn-kix_list_17-8 0
  }
  
  .lst-kix_list_10-2>li:before {
      content: "\0025a0   "
  }
  
  .lst-kix_list_4-6>li {
      counter-increment: lst-ctn-kix_list_4-6
  }
  
  .lst-kix_list_13-7>li {
      counter-increment: lst-ctn-kix_list_13-7
  }
  
  ol.lst-kix_list_17-5.start {
      counter-reset: lst-ctn-kix_list_17-5 0
  }
  
  .lst-kix_list_4-2>li:before {
      content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ". "
  }
  
  .lst-kix_list_4-6>li:before {
      content: "" counter(lst-ctn-kix_list_4-6, decimal) ". "
  }
  
  .lst-kix_list_17-4>li {
      counter-increment: lst-ctn-kix_list_17-4
  }
  
  .lst-kix_list_15-2>li:before {
      content: "" counter(lst-ctn-kix_list_15-2, lower-roman) ". "
  }
  
  .lst-kix_list_10-6>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_9-1>li:before {
      content: "" counter(lst-ctn-kix_list_9-1, lower-latin) ". "
  }
  
  ol.lst-kix_list_12-7.start {
      counter-reset: lst-ctn-kix_list_12-7 0
  }
  
  .lst-kix_list_15-8>li {
      counter-increment: lst-ctn-kix_list_15-8
  }
  
  .lst-kix_list_12-2>li {
      counter-increment: lst-ctn-kix_list_12-2
  }
  
  .lst-kix_list_9-5>li:before {
      content: "" counter(lst-ctn-kix_list_9-5, lower-roman) ". "
  }
  
  .lst-kix_list_12-2>li:before {
      content: "" counter(lst-ctn-kix_list_12-2, lower-roman) ". "
  }
  
  ol.lst-kix_list_12-8.start {
      counter-reset: lst-ctn-kix_list_12-8 0
  }
  
  .lst-kix_list_11-6>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  .lst-kix_list_1-2>li:before {
      content: "\0025a0   "
  }
  
  .lst-kix_list_8-8>li {
      counter-increment: lst-ctn-kix_list_8-8
  }
  
  ol.lst-kix_list_16-1.start {
      counter-reset: lst-ctn-kix_list_16-1 0
  }
  
  ol.lst-kix_list_17-6.start {
      counter-reset: lst-ctn-kix_list_17-6 0
  }
  
  .lst-kix_list_1-6>li:before {
      content: "\0025cf   ";
      transform: scale(0.5);
  }
  
  li.li-bullet-0:before {
      margin-left: -36pt;
      white-space: nowrap;
      display: inline-block;
      min-width: 36pt
  }
  
  .lst-kix_list_18-7>li {
      counter-increment: lst-ctn-kix_list_18-7
  }
  
  .lst-kix_list_12-6>li:before {
      content: "" counter(lst-ctn-kix_list_12-6, decimal) ". "
  }
  
  .lst-kix_list_2-2>li:before {
      content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". "
  }
  
  .lst-kix_list_13-2>li:before {
      content: "" counter(lst-ctn-kix_list_13-2, lower-roman) ". "
  }
  
  ol {
      margin: 0;
    //   padding: 0
  }
  
  table td,
  table th {
    //   padding: 0
  }
  
  .c23 {
      border-right-style: solid;
    //   padding: 5pt 5pt 5pt 5pt;
      border-bottom-color: #121212;
      border-top-width: 1pt;
      border-right-width: 1pt;
      border-left-color: #121212;
      vertical-align: middle;
      border-right-color: #121212;
      border-left-width: 1pt;
      border-top-style: solid;
      border-left-style: solid;
      border-bottom-width: 1pt;
      width: 268.1pt;
      border-top-color: #121212;
      border-bottom-style: solid
  }
  
  .c26 {
      border-right-style: solid;
    //   padding: 5pt 5pt 5pt 5pt;
      border-bottom-color: #121212;
      border-top-width: 1pt;
      border-right-width: 1pt;
      border-left-color: #121212;
      vertical-align: middle;
      border-right-color: #121212;
      border-left-width: 1pt;
      border-top-style: solid;
      border-left-style: solid;
      border-bottom-width: 1pt;
      width: 199.8pt;
      border-top-color: #121212;
      border-bottom-style: solid
  }
  
  .c44 {
      border-right-style: solid;
    //   padding: 5pt 5pt 5pt 5pt;
      border-bottom-color: #121212;
      border-top-width: 1pt;
      border-right-width: 1pt;
      border-left-color: #000000;
      vertical-align: top;
      border-right-color: #000000;
      border-left-width: 1pt;
      border-top-style: solid;
      border-left-style: solid;
      border-bottom-width: 1pt;
      width: 268.1pt;
      border-top-color: #000000;
      border-bottom-style: solid
  }
  
  .c40 {
      border-right-style: solid;
    //   padding: 5pt 5pt 5pt 5pt;
      border-bottom-color: #121212;
      border-top-width: 1pt;
      border-right-width: 1pt;
      border-left-color: #000000;
      vertical-align: top;
      border-right-color: #000000;
      border-left-width: 1pt;
      border-top-style: solid;
      border-left-style: solid;
      border-bottom-width: 1pt;
      width: 199.8pt;
      border-top-color: #000000;
      border-bottom-style: solid
  }
  
  .c3 {
    //   background-color: #ffffff;
      -webkit-text-decoration-skip: none;
    //   color: #0000ff;
      font-weight: 400;
      text-decoration: underline;
      text-decoration-skip-ink: none;
    //   font-size: 12pt;
    //   font-family: "Times New Roman"
  }
  
  .c20 {
    //   padding-top: 10pt;
    //   padding-bottom: 10pt;
    //   line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: left;
      height: 11pt
  }
  
  .c8 {
    //   color: #000000;
      font-weight: 700;
      text-decoration: none;
      vertical-align: baseline;
    //   font-size: 14pt;
    //   font-family: "Times New Roman";
      font-style: normal
  }
  
  .c21 {
    //   padding-top: 0pt;
    //   padding-bottom: 0pt;
    //   line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: left;
      height: 11pt
  }
  
  .c0 {
    //   color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
    //   font-size: 12pt;
    //   font-family: "Times New Roman";
      font-style: normal
  }
  
  .c19 {
    //   color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
    //   font-size: 12pt;
    //   font-family: "Arial";
      font-style: normal
  }
  
  .c12 {
    //   color: #000000;
      font-weight: 700;
      text-decoration: none;
      vertical-align: baseline;
    //   font-size: 12pt;
    //   font-family: "Times New Roman";
      font-style: normal
  }
  
  .c6 {
    //   color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
    //   font-size: 12pt;
    //   font-family: "Times New Roman";
      font-style: italic
  }
  
  .c33 {
    //   padding-top: 10pt;
    //   padding-bottom: 0pt;
    //   line-height: 1.5;
      orphans: 2;
      widows: 2;
      text-align: justify
  }
  
  .c1 {
    //   padding-top: 10pt;
    //   padding-bottom: 10pt;
    //   line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: justify
  }
  
  .c30 {
    //   padding-top: 0pt;
    //   padding-bottom: 0pt;
    //   line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: justify
  }
  
  .c27 {
    //   padding-top: 10pt;
    //   padding-bottom: 10pt;
    //   line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: left
  }
  
  .c28 {
    //   padding-top: 0pt;
    //   padding-bottom: 10pt;
    //   line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: justify
  }
  
  .c42 {
      border-spacing: 0;
      border-collapse: collapse;
      margin-right: auto
  }
  
  .c16 {
    //   background-color: #ffff00;
    //   font-size: 14pt;
    //   font-family: "Times New Roman";
      font-weight: 700
  }
  
  .c37 {
    //   color: #000000;
      font-weight: 400;
    //   font-size: 11pt;
    //   font-family: "Calibri"
  }
  
  .c7 {
    //   font-size: 12pt;
    //   font-family: "Times New Roman";
      font-weight: 400
  }
  
  .c35 {
      text-decoration: none;
      vertical-align: baseline;
      font-style: normal
  }
  
  .c36 {
    //   color: #000000;
      vertical-align: baseline;
      font-style: normal
  }
  
  .c38 {
    //   font-size: 14pt;
    //   font-family: "Times New Roman";
      font-weight: 700
  }
  
  .c9 {
    //   font-size: 12pt;
      font-style: italic;
    //   color: #000000
  }
  
  .c10 {
      -webkit-text-decoration-skip: none;
      text-decoration: underline;
      text-decoration-skip-ink: none
  }
  
  .c15 {
    //   font-size: 12pt;
    //   font-family: "Times New Roman";
      font-weight: 700
  }
  
  .c25 {
      margin-left: 72pt;
    //   padding-left: 0pt
  }
  
  .c17 {
      margin-left: 126pt;
    //   padding-left: 0pt
  }
  
  .c2 {
      margin-left: 90pt;
    //   padding-left: 0pt
  }
  
  .c32 {
      margin-left: 54pt;
    //   padding-left: 0pt
  }
  
  .c46 {
      max-width: 468pt;
    //   padding: 72pt 72pt 72pt 72pt
  }
  
  .c24 {
    //   color: inherit;
      text-decoration: inherit
  }
  
  .c5 {
    //   padding: 0;
      margin: 0
  }
  
  .c18 {
      margin-left: 36pt;
    //   padding-left: 0pt
  }
  
  .c29 {
    //   color: #000000;
    //   font-size: 12pt
  }
  
  .c4 {
    //   background-color: #ffffff
  }
  
  .c13 {
    //   color: #0000ff
  }
  
  .c31 {
      margin-left: 36pt
  }
  
  .c11 {
    //   color: #1155cc
  }
  
  .c41 {
    //   color: #141412
  }
  
  .c22 {
      font-style: italic
  }
  
  .c47 {
    //   padding-left: 18pt
  }
  
  .c43 {
      text-indent: 36pt
  }
  
  .c39 {
    //   background-color: #ffff00
  }
  
  .c14 {
      height: 0pt
  }
  
  .c34 {
      height: 11pt
  }
  
  .c45 {
      margin-left: 72pt
  }
  td {
    padding: 1em;
  }
    
    && li:has(h3):before {
        font-size: 20px;
    
        margin-left: -36pt;
        min-width: 36pt;
        text-align: right;
        padding-right: 6pt;
    }
    
    li:has(h3) {
        padding-left: 0px !important
    }
  `}
`;
