import classNames from "classnames";
import React, { useState, useRef } from "react";
import { EnvelopeSimple as EnvelopeSimpleIcon } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import config from "../../config";
import { CircleNotch as CircleNotchIcon } from "@phosphor-icons/react";

const formFields = [
  { name: "name", label: "Name" },
  { name: "email", label: "Email" },
  { name: "message", label: "Message", textarea: true },
];

const toastConfig = {
  position: "top-right",
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const SuccessMsg = ({ title, content }) => (
  <div className="bg-[#F1FBF8] w-fit h-fit p-4">
    <h5 className="text-[#115B43] font-semibold pb-2">{title}</h5>
    <p className="text-[14px] leading-[18px]">{content}</p>
  </div>
);

const WarnMsg = ({ title, content }) => (
  <div className="bg-[#edc96d] w-fit h-fit p-4">
    <h5 className="text-[#000000] font-semibold pb-2">{title}</h5>
    <p className="text-[14px] leading-[18px]">{content}</p>
  </div>
);

const Form = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [payload, setPayload] = useState({});
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const nameRef = useRef("");
  const emailRef = useRef("");
  const messageRef = useRef("");
  const onSubmit = () => {
    setIsSubmitting(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    fetch(`${config.apiEndpoint}/v1/public/createContactQuery`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        nameRef.current.value = "";
        emailRef.current.value = "";
        messageRef.current.value = "";

        setIsFormSuccess(true);
        setInterval(() => {
          setIsFormSuccess(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div>
      <h3 className="mb-2">Get in touch</h3>
      <div className="flex flex-col gap-6">
        {formFields.map((field) => (
          <div key={field.name} className="flex flex-col gap-2">
            <label htmlFor={field.name} className="text-rms-grey-light">
              {field.label}
            </label>
            {field.textarea ? (
              <textarea
                ref={messageRef}
                onChange={(e) => {
                  setPayload({ ...payload, [field.name]: e.target.value });
                }}
                name={field.name}
                className="bg-transparent border border-rms-grey-light text-rms-grey-light outline-none py-5 px-4 h-36"
              />
            ) : (
              <input
                ref={field.name === "name" ? nameRef : emailRef}
                onChange={(e) => {
                  setPayload({ ...payload, [field.name]: e.target.value });
                }}
                name={field.name}
                className="bg-transparent border border-rms-grey-light text-rms-grey-light outline-none py-5 px-4"
              />
            )}
          </div>
        ))}
        <button
          onClick={() => onSubmit()}
          className="w-full bg-white text-black py-5 text-center"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <span className="flex justify-center items-center gap-2">
              <CircleNotchIcon size={32} className="animate-spin" />{" "}
              <span>SUBMITTING</span>
            </span>
          ) : (
            <>SUBMIT</>
          )}
        </button>
        {isFormSuccess ? (
          <span className="text-green-600 text-center text-xs">
            Submitted successfully !
          </span>
        ) : (
          <span className="text-green-600 text-center text-xs">
            {"       "}
          </span>
        )}
      </div>
    </div>
  );
};

export const NotifyForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const emailRef = useRef("");
  const onSubmit = () => {
    if (email !== "") {
      setIsSubmitting(true);
      fetch(`${config.apiEndpoint}/v1/public/checkSubscription?email=${email}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.subscribed) {
            setIsFormSuccess(true);
            setInterval(() => {
              setIsFormSuccess(false);
            }, 3000);
            emailRef.current.value = "";
          } else {
            fetch(
              `${config.apiEndpoint}/v1/public/manageSubscription?action=subscribe&&email=${email}`
            )
              .then((response) => response.json())
              .then((data) => {
                setIsFormSuccess(true);
                setInterval(() => {
                  setIsFormSuccess(false);
                }, 3000);
                emailRef.current.value = "";
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <>
      <div
        className={classNames(
          "flex flex-col items-end gap-6 mt-6",
          "sm:flex-row"
        )}
      >
        {/* <div className="flex flex-col"> */}
        <div
          className={classNames(
            "border-b border-solid border-rms-grey-light",
            "flex items-center",
            "w-full"
          )}
        >
          <EnvelopeSimpleIcon size={24} className="text-rms-grey-light" />
          <input
            ref={emailRef}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="email"
            placeholder="Enter your email address"
            className={classNames(
              "bg-transparent text-rms-grey-light outline-none py-3 px-4",
              "w-full"
            )}
          />
        </div>
        {isFormSuccess ? (
          <span className="text-green-600 text-xs sm:hidden">
            Submitted successfully !
          </span>
        ) : (
          ""
        )}
        {/* </div> */}
        <button
          onClick={() => onSubmit()}
          className="shrink-0 py-4 px-8 bg-black text-white"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <span className="flex justify-center items-center gap-2">
              <CircleNotchIcon size={32} className="animate-spin" />
            </span>
          ) : (
            <>Notify Me</>
          )}
        </button>
      </div>
      {isFormSuccess ? (
        <span className="text-green-600 text-xs hidden sm:block">
          Submitted successfully !
        </span>
      ) : (
        ""
      )}
    </>
  );
};

const joinUsFormFields = [
  { name: "name", label: "Full Name" },
  { name: "companyName", label: "Company Name" },
  { name: "email", label: "Email Address", type: "email" },
];

export const JoinUsForm = () => {
  const [payload, setPayload] = useState({});
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const nameRef = useRef("");
  const emailRef = useRef("");
  const companyRef = useRef("");
  const interestRef = useRef("");

  const onSubmit = () => {
    setIsSubmitting(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    fetch(`${config.apiEndpoint}/v1/public/createContactQuery`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        nameRef.current.value = "";
        emailRef.current.value = "";
        companyRef.current.value = "";
        interestRef.current.value = "";

        setIsFormSuccess(true);
        setInterval(() => {
          setIsFormSuccess(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <div className="text-center">
        <h2 className="mb-4">JOIN OUR COMMUNITY</h2>
        <p className="text-rms-grey mb-12">
          Get catalogue, licensing, pricing, and launch updates below:
        </p>
      </div>
      <div className="flex flex-col gap-6 max-w-md mx-auto">
        {joinUsFormFields.map((field) => (
          <div key={field.name} className="flex flex-col gap-2">
            <label htmlFor={field.name} className="text-rms-grey">
              {field.label}
            </label>
            <input
              ref={
                field.name === "name"
                  ? nameRef
                  : field.name === "companyName"
                  ? companyRef
                  : emailRef
              }
              onChange={(e) => {
                setPayload({ ...payload, [field.name]: e.target.value });
              }}
              name={field.name}
              className="bg-transparent border border-black text-rms-grey-dark outline-none py-3 px-4"
            />
          </div>
        ))}
        <div className="flex flex-col gap-2">
          <label htmlFor="interest" className="text-rms-grey">
            Interest
          </label>
          <div className="border border-black py-3 px-4">
            <select
              name="interest"
              ref={interestRef}
              onChange={(e) => {
                setPayload({ ...payload, interest: e.target.value });
              }}
              className="max-w-full bg-transparent text-rms-grey-dark outline-none"
            >
              {[
                { label: "-", value: "" },
                {
                  label: "I am a licensor looking to list my IP on Remaster",
                  value: "I am a licensor looking to list my IP on Remaster",
                },
                {
                  label: "I am a licensee looking to buy IP on Remaster",
                  value: "I am a licensee looking to buy IP on Remaster",
                },
                {
                  label: "Other",
                  value: "Other",
                },
              ].map(({ label, value }) => (
                <option key={value} value={value} className="line-clamp-1">
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button
          onClick={() => onSubmit()}
          className="w-full bg-black text-white py-5 text-center"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <span className="flex justify-center items-center gap-2">
              <CircleNotchIcon size={32} className="animate-spin" />{" "}
              <span>SUBMITTING</span>
            </span>
          ) : (
            <>SUBMIT</>
          )}
        </button>
        {isFormSuccess ? (
          <span className="text-green-600 text-center text-xs">
            Submitted successfully !
          </span>
        ) : (
          <span className="text-green-600 text-center text-xs">
            {"       "}
          </span>
        )}
      </div>
    </>
  );
};

export default Form;
