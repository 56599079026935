import React, { useState, useEffect } from 'react';
import Img1 from './HP-1 1.svg'; 
import Img3 from './Img3.svg';
import Img4 from './Img4.svg';
import Footer from '../Footer';
import Buyer from './Buyer.svg';
import Buyer2 from './_239.svg';
import { Skeleton } from 'antd';

const HomePage = () => {
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const handleImageLoad1 = () => {
    setLoading1(false);
  };
  const handleImageLoad2 = () => {
    setLoading2(false);
  };
  const handleImageLoad3 = () => {
    setLoading3(false);
  };

  useEffect(() => {
    const img1 = new Image();
    img1.src = Img1;
    img1.onload = handleImageLoad1;

    const img2 = new Image();
    img2.src = Img3;
    img2.onload = handleImageLoad2;

    const img3 = new Image();
    img3.src = Img4;
    img3.onload = handleImageLoad3;
  }, []);

  return (
    <div className="w-fit lg:w-auto">
      <div className="border border-white-500 bg-[#E7E7E7]">
        <div className="w-[1348px] h-auto mt-[80px] mb-[75px] mx-auto">
          <div className="flex flex-col sm:flex-row justify-between w-full space-x-0 sm:space-x-6 items-center px-[24px]">
            <div className="w-[591px] h-auto flex flex-col justify-between my-auto mx-auto sm:mx-0">
              <div className="space-y-4">
                <div className="font-gt-america font-normal text-custom-40 leading-custom-44 text-left decoration-skip-ink-none">
                  PARTNER TO CREATE LICENSED PRODUCTS
                </div>
                <div className="font-Satoshi font-medium text-custom-20 leading-custom-30 text-left decoration-skip-ink-none">
                  REMASTER simplifies licensing from the first conversation
                  through the last royalty payment.
                </div>
              </div>
              <button
                className="cursor-pointer mt-[60px] w-[292px] h-[72px] px-[32px] py-[16px] font-satoshi text-[18px] font-extrabold leading-[28px] text-center underline-from-font decoration-none bg-[#712EFF] text-white"
                onClick={() =>
                  window.open("https://beta.commerce.remaster.io/seller-list", "_blank")
                }
              >
                Browse Licensable Content
              </button>
            </div>
            <div className="w-full sm:w-[712px] sm:h-[528px] mx-auto sm:mx-0 mt-6 sm:mt-0">
              {loading1 ? (
                <Skeleton.Image
                  active
                  className="w-full h-[242px] sm:h-full object-contain mx-auto"
                />
              ) : (
                <img
                  src={Img1}
                  alt="HP-1 1"
                  className="w-full h-full object-contain mx-auto"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="h-auto">
        <div className="w-[1348px] px-[24px] h-auto mt-[41px] mx-auto flex flex-col sm:flex-row justify-between md:space-x-6 items-center sm:px-6">
          <div className="w-[747px] h-auto flex flex-col sm:mb-0">
            <div className="flex items-center mb-[24px] space-x-4">
              <img src={Buyer} alt="heading" />
              <div className="font-gt-america text-custom-40 font-normal leading-custom-58 text-left decoration-skip-ink-none">
                CONTENT OWNERS
              </div>
            </div>
            <div>
              <div className="font-Satoshi text-[26px] mb-[24px] font-bold leading-[30px] text-left underline-from-font decoration-none">
                Make money by licensing your content.
              </div>
              <div className="font-satoshi text-[20px] font-medium leading-[30px] text-left underline-from-font decoration-none">
                You focus on creating. We make it easy to monetize those
                creations. Showcase your content, find partners, manage those
                relationships all while maintaining creative control.
              </div>
            </div>
            <button
              className="cursor-pointer mt-[24px] w-[328px] h-[60px] flex items-center justify-center font-satoshi text-[18px] font-extrabold leading-[28px] text-center underline-from-font decoration-none bg-[#712EFF] text-white"
              onClick={() =>
                window.open(
                  "https://beta.commerce.remaster.io/signup",
                  "_blank"
                )
              }
            >
              Create Content Owner Account
            </button>
          </div>
          <div className="w-full sm:w-[577px] sm:h-[427px] my-[20px] sm:my-0">
            {loading2 ? (
              <Skeleton.Image
                active
                className="w-full sm:h-full h-[242px] object-contain"
              />
            ) : (
              <img
                src={Img3}
                alt="Img3"
                className="w-full h-full object-contain"
              />
            )}
          </div>
        </div>

        <div className="lg:mt-[120px] px-[24px] w-[1348px] md:space-x-6 mx-auto h-auto mb-[80px] flex flex-col sm:flex-row justify-between items-center sm:px-6">
          <div className="sm:w-[412px] sm:h-[504px] object-contain xl:mt-[63px] xl:mb-[80px] xl:ml-[102px] sm:my-0 my-[20px]">
            {loading3 ? (
              <Skeleton.Image
                active
                className="sm:w-full sm:h-full w-[328px] h-[402px] object-contain"
              />
            ) : (
              <img
                src={Img4}
                alt="Img4"
                className="w-full h-full object-contain mx-auto"
              />
            )}
          </div>
          <div className="w-[587px] h-auto flex flex-col sm:my-0 my-[20px]">
            <div className="flex items-center mb-6 space-x-4">
              <img src={Buyer2} alt="heading" />
              <div className="font-gt-america text-custom-40 font-normal leading-custom-58 text-left decoration-skip-ink-none">
                PRODUCT MAKERS
              </div>
            </div>
            <div>
              <div className="font-satoshi text-[26px] font-bold mb-[24px] leading-[30px] text-left underline-from-font decoration-none">
                Make money by selling your products
              </div>
              <div className="font-satoshi text-[20px] font-medium leading-[30px] text-left underline-from-font decoration-none">
                REMASTER provides an end to end solution that connects product
                makers with hundreds of creators and thousands of their assets,
                plus all the tools to make deals simple and affordable.
              </div>
            </div>
            <button
              className="cursor-pointer mt-[24px] w-[328px] h-[60px] flex items-center justify-center font-satoshi text-[18px] font-extrabold leading-[28px] text-center underline-from-font decoration-none bg-[#712EFF] text-white"
              onClick={() =>
                window.open("https://beta.commerce.remaster.io", "_blank")
              }
            >
              Browse REMASTER Marketplace
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
