import React from "react";
import RemasterLogo from './RemasterLogo.svg';
import Icon1 from './BSIcon1.svg';
import Icon2 from './BSIcon2.svg';
import EnvelopeIcon from './EnvelopeSimple.svg';

const Footer = () => {
  return (
    <div className="w-full h-auto bg-black">
      <div className="text-white h-auto flex items-center justify-center border-b border-white">
        <div className="w-full max-w-[calc(100%-56px)] mx-auto h-auto relative py-[80px]">
          <div className="space-y-4 text-center">
            <div className="flex gap-4">
              <img src={Icon1} alt="Img1" />
              <img src={Icon2} alt="Img2" />
            </div>
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center relative">
              <div className="font-gt-america text-custom-40 font-normal leading-custom-58 text-left decoration-skip-ink-none">
                READY TO MAKE A DEAL?
              </div>
              {/* <div className="flex flex-col gap-[16px] mt-4 mb-4 md:flex-row lg:mt-0 lg:absolute lg:bottom-0 lg:right-0">
                <button className="cursor-pointer w-[225px] h-[72px] flex items-center justify-center font-satoshi text-[18px] font-extrabold leading-[28px] text-center border border-gray-400">
                  Make Seller Inquiry
                </button>
                <button className="cursor-pointer w-[253px] h-[72px] flex items-center justify-center font-satoshi text-[18px] font-extrabold leading-[28px] text-center border border-gray-400">
                  Create Buyer Account
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="text-white h-auto flex flex-col md:flex-row md:items-start md:space-x-6 px-[24px]">
        <div className="w-[370px] md:my-[40px] my-[20px]">
          <img src={RemasterLogo} alt="Logo" />
        </div>
        <div className="flex flex-col sm:flex-row space-x-0 md:space-x-6 md:my-[40px]">
          <div className="w-[173px] space-y-4 my-[10px] sm:my-0">
            <div className="font-gt-america text-[16px] font-normal leading-[19.28px] text-left decoration-skip-ink-none">
              COMPANY
            </div>
            <div className="font-satoshi space-y-2 text-[16px] font-medium leading-[24px] text-left underline-from-font decoration-none">
              <ul
                className="cursor-pointer"
                onClick={() =>
                  window.open("https://remaster.io/terms", "_blank")
                }
              >
                Terms of Use
              </ul>
              <ul
                className="cursor-pointer"
                onClick={() =>
                  window.open("https://remaster.io/privacy", "_blank")
                }
              >
                Privacy Policy
              </ul>
              <ul
                className="cursor-pointer"
                onClick={() =>
                  window.open("https://remaster.io/saas", "_blank")
                }
              >
                Services Agreement
              </ul>
            </div>
          </div>
          <div className="w-[173px] space-y-4 my-[10px] sm:my-0">
            <div className="font-gt-america text-[16px] font-normal leading-[19.28px] text-left decoration-skip-ink-none">
              PRODUCT
            </div>
            <div className="font-satoshi space-y-2 text-[16px] font-medium leading-[24px] text-left underline-from-font decoration-none">
              {/* <ul>About</ul>
              <ul>FAQ</ul> */}
              <ul className="cursor-pointer flex items-center">
                <a
                  href="mailto:founders@remaster.io"
                  className="flex items-center"
                >
                  Contact Support
                  <img
                    src={EnvelopeIcon}
                    alt="Envelope Icon"
                    className="ml-2"
                  />
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
