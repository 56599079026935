import classNames from "classnames";
import gsap from "gsap";
import { useLayoutEffect, useRef } from "react";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ProductSection = () => {
  const scrollTriggerRef = useRef(null);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const headerHeight = document.querySelector("header").offsetHeight;
      const panels = Array.from(
        scrollTriggerRef.current.querySelectorAll(".product-panel")
      );
      panels.forEach((panel, i) => {
        const panelTitle = panel.querySelector(".pannel-title");
        const pinSpace = (panelTitle.offsetTop - panel.offsetTop);

        const end = () =>
          pinSpace * i -
          pinSpace * (panels.length - 1) +
          panel.parentElement.offsetTop +
          panel.parentElement.offsetHeight -
          panel.offsetTop -
          panels[panels.length - 1].offsetHeight;

        ScrollTrigger.create({
          trigger: panel,
          start: () => `top-=${headerHeight + pinSpace * (i + 1)}`,
          pin: true,
          pinSpacing: false,
          end: () => `+=${end()}`,
          invalidateOnRefresh: true,
          scrub: 1,
          // markers: true,
        });
      });
    });

    return () => ctx.revert();
  }, []);
  return (
    <section
      className="container py-20 xl:py-36"
      ref={scrollTriggerRef}
      id="products"
    >
      <h1>OUR PRODUCTS</h1>
      <p className="text-rms-grey mt-2 mb-10 xl:mb-24">
        We power global enterprises with contract and commerce solutions
      </p>
      <div className="flex flex-col gap-6">
        {[
          {
            id: "1",
            title: "Contracts",
            title2: "Remastered",
            description: "Automate Legal Enforceability",
            img: "./product1.webp",
            imgMobile: "./product1-mobile.webp",
            bg: "bg-[#E7E7E7]",
          },
          {
            id: "2",
            title: "Commerce",
            title2: "Remastered",
            description: "Increase Exposure to your IP",
            img: "./product2.webp",
            imgMobile: "./product2-mobile.webp",
            bg: "bg-[#BDBDBD]",
          },
        ].map((tile) => (
          <div
            key={tile.id}
            className={classNames(
              "product-panel",
              "flex flex-col-reverse sm:grid sm:grid-cols-12",
              tile.bg
            )}
          >
            <div
              className={classNames(
                "-mt-20 sm:mt-0 sm:w-full sm:col-span-8 xl:col-span-7 sm:col-start-5 xl:col-start-6 sm:row-start-1",
                "aspect-[5/4]"
              )}
            >
              <img
                src={tile.img}
                className=" object-cover w-full h-full hidden sm:block"
              />
              <img
                src={tile.imgMobile}
                className=" object-cover w-full h-full block sm:hidden"
              />
            </div>
            <div className="px-6 py-8 sm:p-10 xl:p-16 sm:col-span-7 xl:col-span-5 sm:col-start-1 sm:row-start-1 sm:flex sm:flex-col sm:justify-between">
              <h2 className="pannel-title uppercase mb-2"><span className="font-bold">{tile.title}</span>{" "}{tile.title2}</h2>
              <p className="text-black">{tile.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProductSection;
