import React, { useRef, useLayoutEffect } from "react";
import classNames from "classnames";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const infraCards = [
  {
    image: "./infra1.svg",
    title: "Contract",
    desc: "Automating complex legal agreements",
  },
  {
    image: "./infra2.svg",
    title: "Commerce",
    desc: "Removing friction between multiple parties",
  },
  {
    image: "./infra3.svg",
    title: "Payment",
    desc: "Connecting payments directly with legal terms",
  },
  {
    image: "./infra4.svg",
    title: "Data",
    desc: "Third-party data validation to accelerate intermediation",
  },
];

const CardSection = () => {
  const scrollTriggerRef = useRef(null);
  const panelsWrapperRef = useRef(null);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const panelsWrapper = panelsWrapperRef.current;
      const getScrollAmount = () =>
        panelsWrapper.offsetWidth - panelsWrapper.parentElement.offsetWidth;

      gsap.to(panelsWrapper, {
        x: () => -getScrollAmount(),
        ease: "none",
        scrollTrigger: {
          trigger: scrollTriggerRef.current,
          pin: true,
          start: "top",
          scrub: 1,
          end: () => "+=" + getScrollAmount(),
          invalidateOnRefresh: true,
          // markers: true,
        },
      });
    });

    return () => ctx.revert();
  }, []);
  return (
    <div className="bg-black overflow-x-hidden">
      <section className="container" ref={scrollTriggerRef}>
        <div className="py-20 xl:py-20">
          <p className="uppercase text-rms-grey-light">Our infrastructure</p>
          <h2 className="uppercase text-white mt-2 mb-10 xl:mb-20">
            Bridging the Gap
          </h2>
          <div
            ref={panelsWrapperRef}
            className={classNames(
              "flex flex-col sm:grid sm:grid-cols-[repeat(20,1fr)] gap-8",
              "sm:w-[calc(((100%_-_(32px_*_11))_*_20_/_12)_+_(32px_*_19))]",
              "xl:w-[calc(((100%_-_(32px_*_11))_*_16_/_12)_+_(32px_*_15))]",
              "2xl:w-full"
            )}
          >
            {infraCards.map(({ title, desc, image }) => (
              <div
                key={title}
                className={classNames(
                  "rms-card border border-solid border-white text-white sm:col-span-5",
                  "h-100"
                )}
              >
                <div className={classNames("sm:grid sm:grid-rows-[auto_1fr]")}>
                  <div className="flex justify-end">
                    <div className="flex justify-end aspect-square max-w-[154px]">
                      <img alt="Card Icon" src={image} />
                    </div>
                  </div>
                  <div className="px-4 py-8 sm:p-8 sm:pt-[20%] md:pt-[40%]">
                    <h3>{title}</h3>
                    <p className="text-rms-grey-light">{desc}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default CardSection;
