import styled from "styled-components";

export const Styles = styled.div`
  ${() => String.raw`
  .lst-kix_list_4-1>li {
  counter-increment: lst-ctn-kix_list_4-1
  }

  .lst-kix_list_14-1>li:before {
  transform: scale(0.5);
  content: "\0025cb   "
  }

  .lst-kix_list_14-3>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  ul.lst-kix_list_1-0 {
  list-style-type: none
  }

  .lst-kix_list_14-0>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_14-4>li:before {
  transform: scale(0.5);
  content: "\0025cb   "
  }

  .lst-kix_list_14-5>li:before {
  transform: scale(0.5);
  content: "\0025a0   "
  }

  .lst-kix_list_14-7>li:before {
  transform: scale(0.5);
  content: "\0025cb   "
  }

  .lst-kix_list_14-6>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  ul.lst-kix_list_9-3 {
  list-style-type: none
  }

  ul.lst-kix_list_9-4 {
  list-style-type: none
  }

  ul.lst-kix_list_9-1 {
  list-style-type: none
  }

  ul.lst-kix_list_9-2 {
  list-style-type: none
  }

  ul.lst-kix_list_9-7 {
  list-style-type: none
  }

  ul.lst-kix_list_9-8 {
  list-style-type: none
  }

  ul.lst-kix_list_9-5 {
  list-style-type: none
  }

  ul.lst-kix_list_9-6 {
  list-style-type: none
  }

  .lst-kix_list_11-0>li {
  counter-increment: lst-ctn-kix_list_11-0
  }

  ul.lst-kix_list_1-3 {
  list-style-type: none
  }

  ul.lst-kix_list_1-4 {
  list-style-type: none
  }

  ul.lst-kix_list_1-1 {
  list-style-type: none
  }

  ul.lst-kix_list_1-2 {
  list-style-type: none
  }

  ul.lst-kix_list_1-7 {
  list-style-type: none
  }

  ol.lst-kix_list_11-8.start {
  counter-reset: lst-ctn-kix_list_11-8 0
  }

  ul.lst-kix_list_9-0 {
  list-style-type: none
  }

  ul.lst-kix_list_1-8 {
  list-style-type: none
  }

  ul.lst-kix_list_1-5 {
  list-style-type: none
  }

  .lst-kix_list_14-2>li:before {
  transform: scale(0.5);
  content: "\0025a0   "
  }

  ul.lst-kix_list_1-6 {
  list-style-type: none
  }

  .lst-kix_list_4-3>li {
  counter-increment: lst-ctn-kix_list_4-3
  }

  ol.lst-kix_list_4-5.start {
  counter-reset: lst-ctn-kix_list_4-5 0
  }

  ol.lst-kix_list_11-2.start {
  counter-reset: lst-ctn-kix_list_11-2 0
  }

  .lst-kix_list_14-8>li:before {
  transform: scale(0.5);
  content: "\0025a0   "
  }

  .lst-kix_list_5-0>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_5-3>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_5-2>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_5-1>li:before {
  content: "o  "
  }

  .lst-kix_list_5-7>li:before {
  content: "o  "
  }

  ul.lst-kix_list_8-4 {
  list-style-type: none
  }

  ul.lst-kix_list_8-5 {
  list-style-type: none
  }

  .lst-kix_list_5-6>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_5-8>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  ul.lst-kix_list_8-2 {
  list-style-type: none
  }

  ul.lst-kix_list_8-3 {
  list-style-type: none
  }

  ul.lst-kix_list_8-8 {
  list-style-type: none
  }

  ul.lst-kix_list_8-6 {
  list-style-type: none
  }

  ul.lst-kix_list_8-7 {
  list-style-type: none
  }

  .lst-kix_list_5-4>li:before {
  content: "o  "
  }

  .lst-kix_list_5-5>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  ul.lst-kix_list_8-0 {
  list-style-type: none
  }

  ul.lst-kix_list_8-1 {
  list-style-type: none
  }

  .lst-kix_list_6-1>li:before {
  content: "o  "
  }

  .lst-kix_list_6-3>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_6-0>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_6-4>li:before {
  content: "o  "
  }

  ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0
  }

  .lst-kix_list_6-2>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_6-8>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  ol.lst-kix_list_11-7.start {
  counter-reset: lst-ctn-kix_list_11-7 0
  }

  .lst-kix_list_6-5>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_6-7>li:before {
  content: "o  "
  }

  .lst-kix_list_6-6>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_2-7>li:before {
  content: "o  "
  }

  .lst-kix_list_7-4>li:before {
  transform: scale(0.5);
  content: "\0025cb   "
  }

  .lst-kix_list_7-6>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_2-5>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_7-2>li:before {
  transform: scale(0.5);
  content: "\0025a0   "
  }

  ul.lst-kix_list_3-7 {
  list-style-type: none
  }

  ul.lst-kix_list_3-8 {
  list-style-type: none
  }

  ol.lst-kix_list_4-6.start {
  counter-reset: lst-ctn-kix_list_4-6 0
  }

  .lst-kix_list_10-1>li:before {
  content: "o  "
  }

  .lst-kix_list_13-7>li:before {
  content: "o  "
  }

  ul.lst-kix_list_3-1 {
  list-style-type: none
  }

  ul.lst-kix_list_3-2 {
  list-style-type: none
  }

  .lst-kix_list_7-8>li:before {
  transform: scale(0.5);
  content: "\0025a0   "
  }

  ul.lst-kix_list_3-0 {
  list-style-type: none
  }

  ol.lst-kix_list_4-3.start {
  counter-reset: lst-ctn-kix_list_4-3 0
  }

  ul.lst-kix_list_3-5 {
  list-style-type: none
  }

  .lst-kix_list_4-7>li {
  counter-increment: lst-ctn-kix_list_4-7
  }

  ul.lst-kix_list_3-6 {
  list-style-type: none
  }

  ul.lst-kix_list_3-3 {
  list-style-type: none
  }

  ul.lst-kix_list_3-4 {
  list-style-type: none
  }

  .lst-kix_list_10-7>li:before {
  content: "o  "
  }

  .lst-kix_list_10-5>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_10-3>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_4-1>li:before {
  content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ". "
  }

  .lst-kix_list_11-7>li {
  counter-increment: lst-ctn-kix_list_11-7
  }

  .lst-kix_list_9-2>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_4-3>li:before {
  content: "" counter(lst-ctn-kix_list_4-3, decimal) ". "
  }

  .lst-kix_list_4-5>li:before {
  content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". "
  }

  .lst-kix_list_9-0>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  ol.lst-kix_list_4-4.start {
  counter-reset: lst-ctn-kix_list_4-4 0
  }

  .lst-kix_list_9-6>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_11-2>li {
  counter-increment: lst-ctn-kix_list_11-2
  }

  .lst-kix_list_9-4>li:before {
  content: "o  "
  }

  .lst-kix_list_11-3>li:before {
  content: "" counter(lst-ctn-kix_list_11-3, decimal) ". "
  }

  ul.lst-kix_list_2-8 {
  list-style-type: none
  }

  .lst-kix_list_12-3>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_11-5>li:before {
  content: "" counter(lst-ctn-kix_list_11-5, lower-roman) ". "
  }

  .lst-kix_list_12-1>li:before {
  content: "o  "
  }

  ul.lst-kix_list_2-2 {
  list-style-type: none
  }

  ul.lst-kix_list_2-3 {
  list-style-type: none
  }

  ul.lst-kix_list_2-0 {
  list-style-type: none
  }

  ul.lst-kix_list_2-1 {
  list-style-type: none
  }

  .lst-kix_list_9-8>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  ul.lst-kix_list_2-6 {
  list-style-type: none
  }

  .lst-kix_list_1-1>li:before {
  content: "o  "
  }

  ul.lst-kix_list_2-7 {
  list-style-type: none
  }

  .lst-kix_list_11-7>li:before {
  content: "" counter(lst-ctn-kix_list_11-7, lower-latin) ". "
  }

  ul.lst-kix_list_2-4 {
  list-style-type: none
  }

  ul.lst-kix_list_2-5 {
  list-style-type: none
  }

  ul.lst-kix_list_10-0 {
  list-style-type: none
  }

  .lst-kix_list_1-3>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_13-3>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  ul.lst-kix_list_10-8 {
  list-style-type: none
  }

  .lst-kix_list_4-8>li {
  counter-increment: lst-ctn-kix_list_4-8
  }

  ul.lst-kix_list_10-7 {
  list-style-type: none
  }

  .lst-kix_list_1-7>li:before {
  content: "o  "
  }

  ul.lst-kix_list_10-6 {
  list-style-type: none
  }

  ul.lst-kix_list_10-5 {
  list-style-type: none
  }

  ul.lst-kix_list_10-4 {
  list-style-type: none
  }

  ul.lst-kix_list_10-3 {
  list-style-type: none
  }

  .lst-kix_list_1-5>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  ul.lst-kix_list_10-2 {
  list-style-type: none
  }

  ul.lst-kix_list_10-1 {
  list-style-type: none
  }

  .lst-kix_list_13-5>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_12-5>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_12-7>li:before {
  content: "o  "
  }

  .lst-kix_list_2-1>li:before {
  content: "o  "
  }

  .lst-kix_list_2-3>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_11-8>li {
  counter-increment: lst-ctn-kix_list_11-8
  }

  .lst-kix_list_4-2>li {
  counter-increment: lst-ctn-kix_list_4-2
  }

  .lst-kix_list_13-1>li:before {
  content: "o  "
  }

  ol.lst-kix_list_11-6 {
  list-style-type: none
  }

  ol.lst-kix_list_11-7 {
  list-style-type: none
  }

  ol.lst-kix_list_11-8 {
  list-style-type: none
  }

  ol.lst-kix_list_11-2 {
  list-style-type: none
  }

  ol.lst-kix_list_11-3 {
  list-style-type: none
  }

  .lst-kix_list_3-0>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  ol.lst-kix_list_11-4 {
  list-style-type: none
  }

  ol.lst-kix_list_11-5 {
  list-style-type: none
  }

  ul.lst-kix_list_5-7 {
  list-style-type: none
  }

  ul.lst-kix_list_5-8 {
  list-style-type: none
  }

  .lst-kix_list_3-1>li:before {
  content: "o  "
  }

  .lst-kix_list_3-2>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  ul.lst-kix_list_5-5 {
  list-style-type: none
  }

  ol.lst-kix_list_11-0 {
  list-style-type: none
  }

  ul.lst-kix_list_5-6 {
  list-style-type: none
  }

  ol.lst-kix_list_11-1 {
  list-style-type: none
  }

  .lst-kix_list_8-1>li:before {
  content: "o  "
  }

  .lst-kix_list_4-0>li {
  counter-increment: lst-ctn-kix_list_4-0
  }

  .lst-kix_list_8-2>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_3-5>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  ul.lst-kix_list_5-0 {
  list-style-type: none
  }

  .lst-kix_list_3-4>li:before {
  content: "o  "
  }

  ul.lst-kix_list_5-3 {
  list-style-type: none
  }

  .lst-kix_list_3-3>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  ul.lst-kix_list_5-4 {
  list-style-type: none
  }

  ul.lst-kix_list_5-1 {
  list-style-type: none
  }

  .lst-kix_list_8-0>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  ul.lst-kix_list_5-2 {
  list-style-type: none
  }

  .lst-kix_list_8-7>li:before {
  content: "o  "
  }

  ol.lst-kix_list_11-5.start {
  counter-reset: lst-ctn-kix_list_11-5 0
  }

  .lst-kix_list_3-8>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_8-5>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_11-1>li {
  counter-increment: lst-ctn-kix_list_11-1
  }

  .lst-kix_list_8-6>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_8-3>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  ul.lst-kix_list_13-5 {
  list-style-type: none
  }

  ul.lst-kix_list_13-4 {
  list-style-type: none
  }

  ul.lst-kix_list_13-3 {
  list-style-type: none
  }

  .lst-kix_list_3-6>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  ul.lst-kix_list_13-2 {
  list-style-type: none
  }

  ul.lst-kix_list_13-1 {
  list-style-type: none
  }

  .lst-kix_list_3-7>li:before {
  content: "o  "
  }

  ul.lst-kix_list_13-0 {
  list-style-type: none
  }

  .lst-kix_list_8-4>li:before {
  content: "o  "
  }

  .lst-kix_list_11-2>li:before {
  content: "" counter(lst-ctn-kix_list_11-2, lower-roman) ". "
  }

  ul.lst-kix_list_13-8 {
  list-style-type: none
  }

  .lst-kix_list_11-1>li:before {
  content: "" counter(lst-ctn-kix_list_11-1, lower-latin) ". "
  }

  ul.lst-kix_list_13-7 {
  list-style-type: none
  }

  ul.lst-kix_list_13-6 {
  list-style-type: none
  }

  ol.lst-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0
  }

  .lst-kix_list_11-0>li:before {
  content: "" counter(lst-ctn-kix_list_11-0, decimal) ". "
  }

  .lst-kix_list_8-8>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  ol.lst-kix_list_11-6.start {
  counter-reset: lst-ctn-kix_list_11-6 0
  }

  .lst-kix_list_4-4>li {
  counter-increment: lst-ctn-kix_list_4-4
  }

  .lst-kix_list_4-8>li:before {
  content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". "
  }

  .lst-kix_list_4-7>li:before {
  content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". "
  }

  ol.lst-kix_list_4-1.start {
  counter-reset: lst-ctn-kix_list_4-1 0
  }

  ol.lst-kix_list_4-8.start {
  counter-reset: lst-ctn-kix_list_4-8 0
  }

  .lst-kix_list_11-3>li {
  counter-increment: lst-ctn-kix_list_11-3
  }

  ol.lst-kix_list_11-0.start {
  counter-reset: lst-ctn-kix_list_11-0 0
  }

  ul.lst-kix_list_12-6 {
  list-style-type: none
  }

  ul.lst-kix_list_12-5 {
  list-style-type: none
  }

  ul.lst-kix_list_12-4 {
  list-style-type: none
  }

  ul.lst-kix_list_12-3 {
  list-style-type: none
  }

  ul.lst-kix_list_12-2 {
  list-style-type: none
  }

  ul.lst-kix_list_12-1 {
  list-style-type: none
  }

  ul.lst-kix_list_12-0 {
  list-style-type: none
  }

  ul.lst-kix_list_12-8 {
  list-style-type: none
  }

  ul.lst-kix_list_12-7 {
  list-style-type: none
  }

  .lst-kix_list_7-0>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  ol.lst-kix_list_4-7.start {
  counter-reset: lst-ctn-kix_list_4-7 0
  }

  .lst-kix_list_2-6>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_2-4>li:before {
  content: "o  "
  }

  .lst-kix_list_2-8>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_7-1>li:before {
  transform: scale(0.5);
  content: "\0025cb   "
  }

  .lst-kix_list_7-5>li:before {
  transform: scale(0.5);
  content: "\0025a0   "
  }

  .lst-kix_list_7-3>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  ol.lst-kix_list_11-1.start {
  counter-reset: lst-ctn-kix_list_11-1 0
  }

  ul.lst-kix_list_7-5 {
  list-style-type: none
  }

  .lst-kix_list_10-0>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  ul.lst-kix_list_7-6 {
  list-style-type: none
  }

  ul.lst-kix_list_7-3 {
  list-style-type: none
  }

  ul.lst-kix_list_7-4 {
  list-style-type: none
  }

  .lst-kix_list_13-6>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_13-8>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  ul.lst-kix_list_7-7 {
  list-style-type: none
  }

  ul.lst-kix_list_7-8 {
  list-style-type: none
  }

  .lst-kix_list_11-6>li {
  counter-increment: lst-ctn-kix_list_11-6
  }

  ul.lst-kix_list_7-1 {
  list-style-type: none
  }

  ul.lst-kix_list_7-2 {
  list-style-type: none
  }

  ul.lst-kix_list_7-0 {
  list-style-type: none
  }

  .lst-kix_list_7-7>li:before {
  transform: scale(0.5);
  content: "\0025cb   "
  }

  .lst-kix_list_11-4>li {
  counter-increment: lst-ctn-kix_list_11-4
  }

  .lst-kix_list_10-4>li:before {
  content: "o  "
  }

  .lst-kix_list_10-8>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_4-0>li:before {
  content: "" counter(lst-ctn-kix_list_4-0, decimal) ". "
  }

  .lst-kix_list_10-2>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_4-6>li {
  counter-increment: lst-ctn-kix_list_4-6
  }

  .lst-kix_list_4-4>li:before {
  content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". "
  }

  .lst-kix_list_4-2>li:before {
  content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ". "
  }

  .lst-kix_list_4-6>li:before {
  content: "" counter(lst-ctn-kix_list_4-6, decimal) ". "
  }

  .lst-kix_list_9-3>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_10-6>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_9-1>li:before {
  content: "o  "
  }

  ol.lst-kix_list_4-0 {
  list-style-type: none
  }

  ol.lst-kix_list_4-1 {
  list-style-type: none
  }

  ol.lst-kix_list_4-2 {
  list-style-type: none
  }

  ol.lst-kix_list_4-3 {
  list-style-type: none
  }

  .lst-kix_list_9-7>li:before {
  content: "o  "
  }

  .lst-kix_list_11-4>li:before {
  content: "" counter(lst-ctn-kix_list_11-4, lower-latin) ". "
  }

  .lst-kix_list_12-4>li:before {
  content: "o  "
  }

  .lst-kix_list_9-5>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  ul.lst-kix_list_6-6 {
  list-style-type: none
  }

  ul.lst-kix_list_6-7 {
  list-style-type: none
  }

  ul.lst-kix_list_6-4 {
  list-style-type: none
  }

  ul.lst-kix_list_6-5 {
  list-style-type: none
  }

  ul.lst-kix_list_6-8 {
  list-style-type: none
  }

  .lst-kix_list_12-2>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  ol.lst-kix_list_4-8 {
  list-style-type: none
  }

  .lst-kix_list_11-6>li:before {
  content: "" counter(lst-ctn-kix_list_11-6, decimal) ". "
  }

  .lst-kix_list_1-0>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  ol.lst-kix_list_4-4 {
  list-style-type: none
  }

  ul.lst-kix_list_6-2 {
  list-style-type: none
  }

  .lst-kix_list_11-8>li:before {
  content: "" counter(lst-ctn-kix_list_11-8, lower-roman) ". "
  }

  ol.lst-kix_list_4-5 {
  list-style-type: none
  }

  ul.lst-kix_list_6-3 {
  list-style-type: none
  }

  ol.lst-kix_list_11-3.start {
  counter-reset: lst-ctn-kix_list_11-3 0
  }

  .lst-kix_list_1-2>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  ol.lst-kix_list_4-6 {
  list-style-type: none
  }

  ul.lst-kix_list_6-0 {
  list-style-type: none
  }

  .lst-kix_list_12-0>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  ol.lst-kix_list_4-7 {
  list-style-type: none
  }

  ul.lst-kix_list_6-1 {
  list-style-type: none
  }

  .lst-kix_list_1-4>li:before {
  content: "o  "
  }

  .lst-kix_list_13-0>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  ul.lst-kix_list_14-4 {
  list-style-type: none
  }

  ul.lst-kix_list_14-3 {
  list-style-type: none
  }

  ul.lst-kix_list_14-2 {
  list-style-type: none
  }

  .lst-kix_list_13-4>li:before {
  content: "o  "
  }

  ul.lst-kix_list_14-1 {
  list-style-type: none
  }

  ul.lst-kix_list_14-0 {
  list-style-type: none
  }

  .lst-kix_list_1-6>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt
  }

  ul.lst-kix_list_14-8 {
  list-style-type: none
  }

  ul.lst-kix_list_14-7 {
  list-style-type: none
  }

  .lst-kix_list_2-0>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  .lst-kix_list_12-6>li:before {
  transform: scale(0.5);
  content: "\0025cf   "
  }

  ul.lst-kix_list_14-6 {
  list-style-type: none
  }

  .lst-kix_list_11-5>li {
  counter-increment: lst-ctn-kix_list_11-5
  }

  ul.lst-kix_list_14-5 {
  list-style-type: none
  }

  .lst-kix_list_4-5>li {
  counter-increment: lst-ctn-kix_list_4-5
  }

  ol.lst-kix_list_11-4.start {
  counter-reset: lst-ctn-kix_list_11-4 0
  }

  .lst-kix_list_1-8>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_2-2>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_13-2>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  .lst-kix_list_12-8>li:before {
  transform: scale(0.5);
  content: "\0025aa   "
  }

  ol {
  margin: 0;
  // padding: 0
  }

  table td,
  table th {
  // padding: 0
  }

  .c0 {
  margin-left: 54pt;
  // padding-top: 0pt;
  // padding-left: 0pt;
  // padding-bottom: 8pt;
  // line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify
  }

  .c5 {
  margin-left: 36pt;
  // padding-top: 0pt;
  // padding-left: 0pt;
  // padding-bottom: 8pt;
  // line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify
  }

  .c17 {
  margin-left: 36pt;
  // padding-top: 0pt;
  // padding-bottom: 0pt;
  // line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify
  }

  .c1 {
  // color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  // font-size: 12pt;
  // font-family: "Times New Roman";
  font-style: normal
  }

  .c27 {
  // padding-top: 0pt;
  // padding-bottom: 8pt;
  // line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: justify
  }

  .c9 {
  // padding-top: 0pt;
  // padding-bottom: 8pt;
  // line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify
  }

  .c26 {
  // padding-top: 0pt;
  // padding-bottom: 8pt;
  // line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: center
  }

  .c2 {
  // background-color: #ffffff;
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  // color: #1155cc;
  text-decoration: underline
  }

  .c11 {
  text-decoration: none;
  vertical-align: baseline;
  // font-size: 14pt;
  // font-family: "Times New Roman";
  font-style: normal
  }

  .c13 {
  text-decoration: none;
  vertical-align: baseline;
  // font-size: 12pt;
  // font-family: "Times New Roman";
  font-style: normal
  }

  .c15 {
  text-decoration: none;
  vertical-align: baseline;
  // font-size: 22pt;
  // font-family: "Times New Roman";
  font-style: normal
  }

  .c18 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration: underline
  }

  .c24 {
  vertical-align: baseline;
  // font-size: 12pt;
  // font-family: "Times New Roman"
  }

  .c22 {
  margin-left: 108pt;
  // padding-left: 0pt
  }

  .c7 {
  // padding: 0;
  margin: 0
  }

  .c29 {
  vertical-align: baseline;
  font-style: normal
  }

  .c4 {
  // color: inherit;
  text-decoration: inherit
  }

  .c23 {
  max-width: 468pt;
  // padding: 72pt 72pt 72pt 72pt
  }

  .c16 {
  margin-left: 144pt;
  // padding-left: 0pt
  }

  .c25 {
  // font-size: 14pt;
  // font-family: "Times"
  }

  .c10 {
  text-indent: 36pt
  }

  .c14 {
  // background-color: #ffffff
  }

  .c19 {
  // color: #1155cc
  }

  .c8 {
  font-style: italic
  }

  .c21 {
  height: 12pt
  }

  .c28 {
  text-decoration: none
  }

  .c6 {
  font-weight: 700
  }

  .c12 {
  // background-color: #ffff00
  }

  .c20 {
  // color: #0563c1
  }

  .c3 {
  // color: #000000
  }

  .title {
  // padding-top: 24pt;
  // color: #000000;
  font-weight: 700;
  // font-size: 36pt;
  // padding-bottom: 6pt;
  // font-family: "Times New Roman";
  // line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
  }

  .subtitle {
  // padding-top: 18pt;
  // color: #666666;
  // font-size: 24pt;
  // padding-bottom: 4pt;
  // font-family: "Georgia";
  // line-height: 1.0791666666666666;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left
  }

  li {
  // color: #000000;
  // font-size: 12pt;
  // font-family: "Times New Roman"
  }

  p {
  margin: 0;
  // color: #000000;
  // font-size: 12pt;
  // font-family: "Times New Roman"
  }

  h1 {
  // padding-top: 0pt;
  // color: #000000;
  font-weight: 700;
  // font-size: 14pt;
  // padding-bottom: 8pt;
  // font-family: "Times New Roman";
  // line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify
  }

  h2 {
  // padding-top: 18pt;
  // color: #000000;
  font-weight: 700;
  // font-size: 18pt;
  // padding-bottom: 4pt;
  // font-family: "Times New Roman";
  // line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
  }

  h3 {
  // padding-top: 14pt;
  // color: #000000;
  font-weight: 700;
  // font-size: 14pt;
  // padding-bottom: 4pt;
  // font-family: "Times New Roman";
  // line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
  }

  h4 {
  // padding-top: 12pt;
  // color: #000000;
  font-weight: 700;
  // font-size: 12pt;
  // padding-bottom: 2pt;
  // font-family: "Times New Roman";
  // line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
  }

  h5 {
  // padding-top: 11pt;
  // color: #000000;
  font-weight: 700;
  // font-size: 11pt;
  // padding-bottom: 2pt;
  // font-family: "Times New Roman";
  // line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
  }

  h6 {
  // padding-top: 10pt;
  // color: #000000;
  font-weight: 700;
  // font-size: 10pt;
  // padding-bottom: 2pt;
  // font-family: "Times New Roman";
  // line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
  }
  `}
`;
