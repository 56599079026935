import styled from "styled-components";

export const Styles = styled.div`
  ${() => String.raw`
    .lst-kix_n1wras69mofd-0>li {
        counter-increment: lst-ctn-kix_n1wras69mofd-0
    }
    
    ol.lst-kix_n1wras69mofd-6.start {
        counter-reset: lst-ctn-kix_n1wras69mofd-6 0
    }
    
    ol.lst-kix_n1wras69mofd-3 {
        list-style-type: none
    }
    
    .lst-kix_n1wras69mofd-6>li {
        counter-increment: lst-ctn-kix_n1wras69mofd-6
    }
    
    ol.lst-kix_n1wras69mofd-2 {
        list-style-type: none
    }
    
    .lst-kix_n1wras69mofd-3>li {
        counter-increment: lst-ctn-kix_n1wras69mofd-3
    }
    
    ol.lst-kix_n1wras69mofd-1 {
        list-style-type: none
    }
    
    ol.lst-kix_n1wras69mofd-0 {
        list-style-type: none
    }
    
    ol.lst-kix_n1wras69mofd-1.start {
        counter-reset: lst-ctn-kix_n1wras69mofd-1 0
    }
    
    ol.lst-kix_n1wras69mofd-3.start {
        counter-reset: lst-ctn-kix_n1wras69mofd-3 0
    }
    
    .lst-kix_n1wras69mofd-5>li {
        counter-increment: lst-ctn-kix_n1wras69mofd-5
    }
    
    .lst-kix_n1wras69mofd-2>li {
        counter-increment: lst-ctn-kix_n1wras69mofd-2
    }
    
    .lst-kix_n1wras69mofd-0>li:before {
        content: "" counter(lst-ctn-kix_n1wras69mofd-0, decimal) ". "
    }
    
    ol.lst-kix_n1wras69mofd-7 {
        list-style-type: none
    }
    
    ol.lst-kix_n1wras69mofd-6 {
        list-style-type: none
    }
    
    ol.lst-kix_n1wras69mofd-8.start {
        counter-reset: lst-ctn-kix_n1wras69mofd-8 0
    }
    
    .lst-kix_n1wras69mofd-1>li:before {
        content: "" counter(lst-ctn-kix_n1wras69mofd-0, decimal) "." counter(lst-ctn-kix_n1wras69mofd-1, decimal) ". "
    }
    
    ol.lst-kix_n1wras69mofd-5 {
        list-style-type: none
    }
    
    ol.lst-kix_n1wras69mofd-5.start {
        counter-reset: lst-ctn-kix_n1wras69mofd-5 0
    }
    
    ol.lst-kix_n1wras69mofd-4 {
        list-style-type: none
    }
    
    ol.lst-kix_n1wras69mofd-0.start {
        counter-reset: lst-ctn-kix_n1wras69mofd-0 0
    }
    
    ol.lst-kix_n1wras69mofd-8 {
        list-style-type: none
    }
    
    .lst-kix_n1wras69mofd-4>li:before {
        content: "" counter(lst-ctn-kix_n1wras69mofd-0, decimal) "." counter(lst-ctn-kix_n1wras69mofd-1, decimal) "." counter(lst-ctn-kix_n1wras69mofd-2, decimal) "." counter(lst-ctn-kix_n1wras69mofd-3, decimal) "." counter(lst-ctn-kix_n1wras69mofd-4, decimal) ". "
    }
    
    .lst-kix_n1wras69mofd-3>li:before {
        content: "" counter(lst-ctn-kix_n1wras69mofd-0, decimal) "." counter(lst-ctn-kix_n1wras69mofd-1, decimal) "." counter(lst-ctn-kix_n1wras69mofd-2, decimal) "." counter(lst-ctn-kix_n1wras69mofd-3, decimal) ". "
    }
    
    .lst-kix_n1wras69mofd-5>li:before {
        content: "" counter(lst-ctn-kix_n1wras69mofd-0, decimal) "." counter(lst-ctn-kix_n1wras69mofd-1, decimal) "." counter(lst-ctn-kix_n1wras69mofd-2, decimal) "." counter(lst-ctn-kix_n1wras69mofd-3, decimal) "." counter(lst-ctn-kix_n1wras69mofd-4, decimal) "." counter(lst-ctn-kix_n1wras69mofd-5, decimal) ". "
    }
    
    .lst-kix_n1wras69mofd-2>li:before {
        content: "" counter(lst-ctn-kix_n1wras69mofd-0, decimal) "." counter(lst-ctn-kix_n1wras69mofd-1, decimal) "." counter(lst-ctn-kix_n1wras69mofd-2, decimal) ". "
    }
    
    .lst-kix_n1wras69mofd-6>li:before {
        content: "" counter(lst-ctn-kix_n1wras69mofd-0, decimal) "." counter(lst-ctn-kix_n1wras69mofd-1, decimal) "." counter(lst-ctn-kix_n1wras69mofd-2, decimal) "." counter(lst-ctn-kix_n1wras69mofd-3, decimal) "." counter(lst-ctn-kix_n1wras69mofd-4, decimal) "." counter(lst-ctn-kix_n1wras69mofd-5, decimal) "." counter(lst-ctn-kix_n1wras69mofd-6, decimal) ". "
    }
    
    .lst-kix_n1wras69mofd-7>li {
        counter-increment: lst-ctn-kix_n1wras69mofd-7
    }
    
    ol.lst-kix_n1wras69mofd-7.start {
        counter-reset: lst-ctn-kix_n1wras69mofd-7 0
    }
    
    li.li-bullet-0:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }
    
    ol.lst-kix_n1wras69mofd-2.start {
        counter-reset: lst-ctn-kix_n1wras69mofd-2 0
    }
    
    .lst-kix_n1wras69mofd-8>li {
        counter-increment: lst-ctn-kix_n1wras69mofd-8
    }
    
    .lst-kix_n1wras69mofd-8>li:before {
        content: "" counter(lst-ctn-kix_n1wras69mofd-0, decimal) "." counter(lst-ctn-kix_n1wras69mofd-1, decimal) "." counter(lst-ctn-kix_n1wras69mofd-2, decimal) "." counter(lst-ctn-kix_n1wras69mofd-3, decimal) "." counter(lst-ctn-kix_n1wras69mofd-4, decimal) "." counter(lst-ctn-kix_n1wras69mofd-5, decimal) "." counter(lst-ctn-kix_n1wras69mofd-6, decimal) "." counter(lst-ctn-kix_n1wras69mofd-7, decimal) "." counter(lst-ctn-kix_n1wras69mofd-8, decimal) ". "
    }
    
    .lst-kix_n1wras69mofd-1>li {
        counter-increment: lst-ctn-kix_n1wras69mofd-1
    }
    
    .lst-kix_n1wras69mofd-4>li {
        counter-increment: lst-ctn-kix_n1wras69mofd-4
    }
    
    .lst-kix_n1wras69mofd-7>li:before {
        content: "" counter(lst-ctn-kix_n1wras69mofd-0, decimal) "." counter(lst-ctn-kix_n1wras69mofd-1, decimal) "." counter(lst-ctn-kix_n1wras69mofd-2, decimal) "." counter(lst-ctn-kix_n1wras69mofd-3, decimal) "." counter(lst-ctn-kix_n1wras69mofd-4, decimal) "." counter(lst-ctn-kix_n1wras69mofd-5, decimal) "." counter(lst-ctn-kix_n1wras69mofd-6, decimal) "." counter(lst-ctn-kix_n1wras69mofd-7, decimal) ". "
    }
    
    ol.lst-kix_n1wras69mofd-4.start {
        counter-reset: lst-ctn-kix_n1wras69mofd-4 0
    }
    
    ol {
        margin: 0;
        // padding: 0
    }
    
    table td,
    table th {
        // padding: 0
    }
    
    .c6 {
        border-right-style: solid;
        // padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 292.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }
    
    .c7 {
        border-right-style: solid;
        // padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 184.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }
    
    .c11 {
        margin-left: 18pt;
        // padding-top: 0pt;
        // padding-left: 18pt;
        // padding-bottom: 12pt;
        // line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }
    
    .c1 {
        margin-left: 36pt;
        // padding-top: 0pt;
        text-indent: -36pt;
        // padding-bottom: 12pt;
        // line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }
    
    .c17 {
        // padding-top: 0pt;
        // padding-bottom: 6pt;
        // line-height: 0.9375;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt
    }
    
    .c23 {
        // padding-top: 0pt;
        // padding-bottom: 0pt;
        // line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt
    }
    
    .c10 {
        // padding-top: 0pt;
        text-indent: 36pt;
        // padding-bottom: 10pt;
        // line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }
    
    .c34 {
        // padding-top: 0pt;
        // padding-bottom: 0pt;
        // line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt
    }
    
    .c27 {
        // padding-top: 12pt;
        // padding-bottom: 12pt;
        // line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }
    
    .c18 {
        // padding-top: 0pt;
        // padding-bottom: 10pt;
        // line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }
    
    .c16 {
        // padding-top: 0pt;
        // padding-bottom: 6pt;
        // line-height: 0.9375;
        orphans: 2;
        widows: 2;
        text-align: center
    }
    
    .c24 {
        // padding-top: 0pt;
        // padding-bottom: 6pt;
        // line-height: 0.9375;
        orphans: 2;
        widows: 2;
        text-align: left
    }
    
    .c2 {
        // padding-top: 0pt;
        // padding-bottom: 12pt;
        // line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }
    
    .c20 {
        // padding-top: 0pt;
        // padding-bottom: 12pt;
        // line-height: 1.0;
        text-indent: 36pt;
        text-align: justify
    }
    
    .c35 {
        // padding-top: 0pt;
        // padding-bottom: 12pt;
        // line-height: 1.0;
        text-align: justify
    }
    
    .c30 {
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
    }
    
    .c9 {
        // color: #000000;
        text-decoration: none;
        vertical-align: baseline;
        font-style: normal
    }
    
    .c4 {
        -webkit-text-decoration-skip: none;
        text-decoration: underline;
        text-decoration-skip-ink: none
    }
    
    .c29 {
        text-decoration: none;
        vertical-align: baseline;
        font-style: normal
    }
    
    .c32 {
        // color: #000000;
        vertical-align: baseline;
        font-style: normal
    }
    
    .c28 {
        // background-color: #ffffff;
        max-width: 468pt;
        // padding: 72pt 72pt 72pt 72pt
    }
    
    .c33 {
        font-weight: 400;
        font-size: 11pt;
        // font-family: "Arial"
    }
    
    .c0 {
        font-size: 12pt;
        // font-family: "Times New Roman";
        font-weight: 700
    }
    
    .c5 {
        font-size: 12pt;
        // font-family: "Times New Roman";
        font-weight: 400
    }
    
    .c12 {
        // padding: 0;
        margin: 0
    }
    
    .c8 {
        margin-left: 54pt;
        // padding-left: 18pt
    }
    
    .c13 {
        // color: inherit;
        text-decoration: inherit
    }
    
    .c15 {
        margin-left: 18pt;
        // padding-left: 18pt
    }
    
    .c3 {
        margin-left: 90pt;
        // padding-left: 18pt
    }
    
    .c25 {
        vertical-align: super
    }
    
    .c19 {
        font-style: italic
    }
    
    .c26 {
        // color: #0000ff
    }
    
    .c21 {
        // color: #0563c1
    }
    
    .c31 {
        // color: #1155cc
    }
    
    .c22 {
        // color: #1b1b1b
    }
    
    .c14 {
        height: 0pt
    }
    
    .title {
        // padding-top: 0pt;
        // color: #000000;
        font-size: 26pt;
        // padding-bottom: 3pt;
        // font-family: "Arial";
        // line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }
    
    .subtitle {
        // padding-top: 0pt;
        // color: #666666;
        font-size: 15pt;
        // padding-bottom: 16pt;
        // font-family: "Arial";
        // line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }
    
    li {
        // color: #000000;
        font-size: 11pt;
        // font-family: "Arial"
    }
    
    p {
        margin: 0;
        // color: #000000;
        font-size: 11pt;
        // font-family: "Arial"
    }
    
    h1 {
        // padding-top: 20pt;
        // color: #000000;
        font-size: 20pt;
        // padding-bottom: 6pt;
        // font-family: "Arial";
        // line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }
    
    h2 {
        // padding-top: 18pt;
        // color: #000000;
        font-size: 16pt;
        // padding-bottom: 6pt;
        // font-family: "Arial";
        // line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }
    
    h3 {
        // padding-top: 16pt;
        // color: #434343;
        font-size: 14pt;
        // padding-bottom: 4pt;
        // font-family: "Arial";
        // line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }
    
    h4 {
        // padding-top: 14pt;
        // color: #666666;
        font-size: 12pt;
        // padding-bottom: 4pt;
        // font-family: "Arial";
        // line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }
    
    h5 {
        // padding-top: 12pt;
        // color: #666666;
        font-size: 11pt;
        // padding-bottom: 4pt;
        // font-family: "Arial";
        // line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }
    
    h6 {
        // padding-top: 12pt;
        // color: #666666;
        font-size: 11pt;
        // padding-bottom: 4pt;
        // font-family: "Arial";
        // line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }
  `}
`;
