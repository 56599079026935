import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import Branding from "../../components/Branding";
import { content } from "./html_doc";
import { Styles } from "./Styled";

const Privacy = () => {
  return (
    <div className="h-screen overflow-y-scroll">
      <article className="container mx-auto my-10 prose prose-ol:pl-0 prose-ol:list-inside">
        <Branding />
        <h1>REMASTER PRIVACY POLICY</h1>
        <Styles>
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            components={{
              span: ({node, ...props}) => {
                if ((props.className || "").split(" ").includes("c8")) {
                  return (
                    <h3 style={{ display: "inline" }}>
                      <span {...props} />
                    </h3>
                  );
                }
                return <span {...props} />;
              }
            }}
          >
            {content}
          </ReactMarkdown>
        </Styles>
      </article>
    </div>
  );
};

export default Privacy;
