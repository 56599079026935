import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './Logo.svg';
import { List,ArrowRight } from "@phosphor-icons/react";

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="w-auto">
      <nav className="flex justify-between items-center h-[72px] bg-white text-black">
        <div className="flex items-center space-x-[40px]">
          <div className="ml-[24px]">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="hidden md:flex lg:flex space-x-[40px]">
            <NavLink
              to=""
              className={({ isActive }) =>
                `${
                  isActive ? "text-black border-b-2 border-black" : "text-black"
                }
                font-satoshi text-[18px] font-bold leading-[28px] text-center underline-from-font decoration-none flex items-center justify-center h-[72px] pb-1`
              }
            >
              Home
            </NavLink>
            <NavLink
              to="/content_owner"
              className={({ isActive }) =>
                `${
                  isActive ? "text-black border-b-2 border-black" : "text-black"
                }
                font-satoshi text-[18px] font-bold leading-[28px] text-center underline-from-font decoration-none flex items-center justify-center h-[72px] pb-1`
              }
            >
              Content Owners
            </NavLink>
            <NavLink
              to="/product_maker"
              className={({ isActive }) =>
                `${
                  isActive ? "text-black border-b-2 border-black" : "text-black"
                }
                font-satoshi text-[18px] font-bold leading-[28px] text-center underline-from-font decoration-none flex items-center justify-center h-[72px] pb-1`
              }
            >
              Product Makers
            </NavLink>
          </div>
        </div>
        <div className="hidden lg:flex md:flex">
          <button
            className="font-satoshi text-[18px] font-bold cursor-pointer w-[268px] h-[72px] text-white bg-[#712EFF] flex items-center justify-center"
            onClick={() =>
              window.open("https://beta.commerce.remaster.io/", "_blank")
            }
          >
            REMASTER Marketplace
          </button>

          <button
            className="font-satoshi text-[18px] font-bold cursor-pointer w-[122px] h-[72px] text-white bg-black flex items-center justify-center"
            onClick={() =>
              window.open("https://beta.commerce.remaster.io/signin", "_blank")
            }
          >
            Sign In
          </button>
        </div>

        <div className="lg:hidden md:hidden flex items-center mr-[16px]">
          <button
            onClick={toggleSidebar}
            className="text-black"
            aria-label="Toggle sidebar"
          >
            <List size={24} />
          </button>
        </div>
      </nav>

      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50"
          onClick={toggleSidebar}
        >
          <div
            className="fixed right-0 top-0 h-full w-64 bg-white shadow-lg p-2 space-y-4 flex flex-col"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={toggleSidebar}
              className="text-black"
              aria-label="Toggle sidebar"
            >
              <ArrowRight size={24} />
            </button>
            <NavLink
              to=""
              className="cursor-pointer text-black font-satoshi text-[18px] font-bold"
              onClick={toggleSidebar}
            >
              Home
            </NavLink>
            <NavLink
              to="/content_owner"
              className="cursor-pointer text-black font-satoshi text-[18px] font-bold"
              onClick={toggleSidebar}
            >
              Content Owners
            </NavLink>
            <NavLink
              to="/product_maker"
              className="cursor-pointer text-black font-satoshi text-[18px] font-bold"
              onClick={toggleSidebar}
            >
              Product Makers
            </NavLink>
            <div
              className="cursor-pointer text-white font-satoshi text-[18px] font-bold bg-[#712EFF] text-center px-3 py-3 cursor-pointer"
              onClick={() => {
                window.open("https://beta.commerce.remaster.io/", "_blank");
                toggleSidebar();
              }}
            >
              REMASTER Marketplace
            </div>
            <div
              className="cursor-pointer text-white font-satoshi text-[18px] font-bold bg-black text-center px-3 py-3"
              onClick={() => {
                window.open(
                  "https://beta.commerce.remaster.io/signin",
                  "_blank"
                );
                toggleSidebar();
              }}
            >
              Sign In
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
